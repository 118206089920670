import React from 'react'
import { SvgIconProps } from './type'

const Copy: React.FC<SvgIconProps> = ({ height = 21, width = 20, color = '#1F91FA' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16675 8.83341C8.70651 8.83341 8.33341 9.20651 8.33341 9.66675V17.1667C8.33341 17.627 8.70651 18.0001 9.16675 18.0001H16.6667C17.127 18.0001 17.5001 17.627 17.5001 17.1667V9.66675C17.5001 9.20651 17.127 8.83341 16.6667 8.83341H9.16675ZM6.66675 9.66675C6.66675 8.28604 7.78604 7.16675 9.16675 7.16675H16.6667C18.0475 7.16675 19.1667 8.28604 19.1667 9.66675V17.1667C19.1667 18.5475 18.0475 19.6667 16.6667 19.6667H9.16675C7.78604 19.6667 6.66675 18.5475 6.66675 17.1667V9.66675Z"
        fill={color}
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M3.33325 2.99992C3.11224 2.99992 2.90028 3.08772 2.744 3.244C2.58772 3.40028 2.49992 3.61224 2.49992 3.83325V11.3333C2.49992 11.5543 2.58772 11.7662 2.744 11.9225C2.90028 12.0788 3.11224 12.1666 3.33325 12.1666H4.16659C4.62682 12.1666 4.99992 12.5397 4.99992 12.9999C4.99992 13.4602 4.62682 13.8333 4.16659 13.8333H3.33325C2.67021 13.8333 2.03433 13.5699 1.56548 13.101C1.09664 12.6322 0.833252 11.9963 0.833252 11.3333V3.83325C0.833252 3.17021 1.09664 2.53433 1.56548 2.06548C2.03433 1.59664 2.67021 1.33325 3.33325 1.33325H10.8333C11.4963 1.33325 12.1322 1.59664 12.601 2.06548C13.0699 2.53433 13.3333 3.17021 13.3333 3.83325V4.66659C13.3333 5.12682 12.9602 5.49992 12.4999 5.49992C12.0397 5.49992 11.6666 5.12682 11.6666 4.66659V3.83325C11.6666 3.61224 11.5788 3.40028 11.4225 3.244C11.2662 3.08772 11.0543 2.99992 10.8333 2.99992H3.33325Z" fill={color} />
    </svg>
  )
}

export default Copy