import * as React from "react";
import PrimaryBtn from "../Button/PrimaryBtn";
import BaseModal, { BaseModalProps } from "./BaseModal";
import "./styles.scss";
import { QuizzesType } from "../../types";
import { Alert, Checkbox, Col, Row } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { useRecoilState, useRecoilValue } from "recoil";
import pointRuleSelector from "../../recoil/pointRuleSelector";
import resultRecordAtom from "../../recoil/resultRecordAtom";

interface Props extends BaseModalProps {
  onClose: () => void;
  quiz: QuizzesType;
  quizIndex: number;
}

function QuizzesModal(props: Props) {
  const { quiz, onClose, quizIndex, ...rest } = props;
  const { word, options, answers } = quiz;
  const [userAnswers, setUserAnswers] = React.useState<string[]>([]);
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const pointRule = useRecoilValue(pointRuleSelector);
  const [resultRecord, setResultRecord] = useRecoilState(resultRecordAtom);

  const handleAnswer = (checked: boolean, option: string) => {
    if (checked) {
      setUserAnswers([...userAnswers, option]);
    } else {
      setUserAnswers(userAnswers.filter((answer) => answer !== option));
    }
  };

  const isCorrect = React.useMemo(() => {
    if (answers.length !== userAnswers.length) return false;
    const answerMap = answers.reduce((map: Record<string, boolean>, answer) => {
      map[answer] = true;
      return map;
    }, {});
    return userAnswers.every((userAnswer) => answerMap[userAnswer]);
  }, [answers, userAnswers]);

  const handleSubmit = () => {
    const { show_at, options, ...restQuiz } = quiz;
    setResultRecord({
      ...resultRecord,
      quizzes: [
        ...resultRecord.quizzes,
        {
          ...restQuiz,
          result: isCorrect,
          point: isCorrect
            ? pointRule.addQuizNumber
            : pointRule.subtractQuizNumber,
          answers: userAnswers
        },
      ],
    });
    setIsSubmitted(true);
  };

  return (
    <BaseModal
      title={`Câu hỏi ${quizIndex + 1}`}
      footer={
        !isSubmitted ? (
          <PrimaryBtn
            disabled={userAnswers.length === 0}
            onClick={handleSubmit}
          >
            Trả lời
          </PrimaryBtn>
        ) : (
          <PrimaryBtn onClick={onClose}>Tiếp tục</PrimaryBtn>
        )
      }
      centered
      {...rest}
    >
      <p>{word}</p>
      <Row gutter={[10, 10]}>
        {options.map((option) => (
          <Col span={12}>
            <Checkbox
              checked={userAnswers.includes(option)}
              onChange={(e) => handleAnswer(e.target.checked, option)}
              disabled={isSubmitted}
            >
              {option}
            </Checkbox>
          </Col>
        ))}
      </Row>

      {isSubmitted && (
        <div style={{ marginTop: 15 }}>
          {isCorrect ? (
            <Alert
              message="Chính xác."
              type="info"
              showIcon
              icon={<CheckCircleFilled />}
            />
          ) : (
            <Alert message="Không chính xác." type="error" showIcon />
          )}
        </div>
      )}
    </BaseModal>
  );
}

export default QuizzesModal;
