import React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import imagesAssets from '../../assets/imgs'
import { brandMyVideo, brandTotal } from '../../containers/HomePage/constant'
import customerBrandsAtom from '../../recoil/customerBrandAtom'
import userInfoAtom from '../../recoil/userInfoAtom'
import videosAtom from '../../recoil/videosAtom'
import { CustomerBranch } from '../../types'
import MainButton from '../Button/MainButton'
import { CategoryItem } from '../Item'
import BaseModal from '../Modal/BaseModal'
import Svg from '../Svg'
import './menu.scss'

type Props = {
  open: boolean
  onCancel?: () => void
}

const MenuMobile: React.FC<Props> = ({ open, onCancel }) => {
  const [userInfo] = useRecoilState(userInfoAtom);
  const setCustomerBrands = useSetRecoilState(customerBrandsAtom);
  const customerBrandsStore = useRecoilValue(customerBrandsAtom);
  const [videosStore, setVideosStore] = useRecoilState(videosAtom)
  const { customerId } = videosStore?.filter ?? {}

  const handleSelectBrand = (item: CustomerBranch) => {
    setVideosStore(value => ({
      ...value, filter: {
        ...value?.filter,
        customerId: item?._id
      }
    }))
  }

  const renderOtherLineITem = () => {
    const brands = [brandTotal]

    if (userInfo?.email) {
      brands.push(brandMyVideo)
    }

    return (<>
      {brands.map((item) => <CategoryItem imageActive={item.imgActive} isActive={customerId === item._id} onClick={() => handleSelectBrand(item)} image={item.img} name={item.name} />)}
      <hr />
      <CategoryItem isActive={false} onClick={() => null} imageActive={imagesAssets.help} image={imagesAssets.help} name='Trợ giúp' />
      <hr />
      <CategoryItem isActive={false} onClick={() => null} imageActive={imagesAssets.feedback} image={imagesAssets.feedback} name='Phản hồi' />
    </>)
  }

  const renderLine = (item: CustomerBranch) => {
    return <CategoryItem imageActive={imagesAssets.item} key={item._id} isActive={customerId === item?._id} onClick={() => handleSelectBrand(item)} image={imagesAssets.item} name={item.name} />
  }

  const renderCustomerBrands = () => {
    const categoriesMenu = customerBrandsStore?.customerBrands;

    return categoriesMenu ? categoriesMenu?.map((item) => renderLine(item)) : <></>
  }

  const handleClose = () => {
    onCancel && onCancel()
  }

  const renderClose = () => {
    return (<div onClick={handleClose} className='absolute top-14 right-7'><Svg.Close color='#ffffff' /> </div>)
  }

  return (
    <BaseModal
      className="menuMobile bg-transparent"
      open={open}
      onCancel={onCancel}
    >
      <div className="menuMobileWrapper h-full md:justify-center ">
        <div className='bg-white w-[80%] h-full flex overflow-y-auto flex-col justify-between'>
          <div>
            {renderOtherLineITem()}
            {/* <div className='border-y border-[#E5E5E5] p-3 pl-5 text-[#606060]'>
              NHÃN HÀNG
            </div>
            <div className='pt-4'>
              {renderCustomerBrands()}
            </div> */}
          </div>
          <div className="pb-5 pt-5 border-t border-t-[#E5E5E5] px-5">
            <a href="#"><p className="py-1 text-base font-semibold">Giới thiệu</p></a>
            <a><p className="py-1 text-base font-semibold">Deepview trên báo</p></a>
            <a><p className="py-1 text-base font-semibold">Đội ngũ</p></a>
            <a><p className="py-1 text-base font-semibold">Tuyển dụng</p></a>
            <a><p className="py-1 text-base font-semibold">Cửa hàng</p></a>

            <p className="py-1 text-sm mt-5 font-medium">Điện thoại: 0779611553</p>
            <p className="py-1 text-sm font-medium">Email: admin@deepview.win</p>
            <p className="py-1 text-sm font-medium">Chat: m.me/ilovedeepview</p>
            <p className="py-1 mt-5 text-sm font-medium">© 2022 Deepview</p>
          </div>
        </div>
        {renderClose()}
      </div>
    </BaseModal>
  )
}

export default MenuMobile