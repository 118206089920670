import * as React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import "./App.scss";

import VideoQuiz from "../containers/VideoQuiz";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomePage from "../containers/HomePage";
import AppTrigger from "./AppTrigger";
import pathUrl from "../routes/path";

function App() {
  return (
    <Router>
      <Header />
      <div className="App">
        <Switch>
          <Route exact path={pathUrl.videoDetail()} component={VideoQuiz} />
          <Route exact path={pathUrl.videoByCategory()} component={VideoQuiz} />
          <Route exact path={pathUrl.videoCategory()} component={HomePage} />
          <Route path={pathUrl.home()} component={HomePage} />
        </Switch>
        <AppTrigger />
      </div>
    </Router>
  );
}

export default App;
