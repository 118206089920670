import * as React from "react";
import imagesAssets from "../../../assets/imgs";
import MainButton from "../../Button/MainButton";
import Svg from "../../Svg";
import BaseModal, { BaseModalProps } from "../BaseModal";
import "./styles.scss";

interface Props extends BaseModalProps {
  onCancel: () => void;
}

const ThanksModal: React.FC<Props> = (props) => {

  const handleClose = () => {
    props.onCancel && props.onCancel()
  }

  return (
    <BaseModal
      className="thanksModal"
      {...props}
    >
      <div className="thanksWrapper md:justify-center">
        <div className="flex justify-center">
          <img className="self-center" src={imagesAssets.thanks} alt="thanks" />
        </div>
        <div className="context">
          <span className="icon"><Svg.Done height={20} width={20} /></span>
          Bạn vừa đặt hàng thành công. Vui lòng chờ xác nhận từ hệ thống.
        </div>
        <div>
          <MainButton className="closeBtn" onClick={handleClose}>
            Đóng
          </MainButton>
        </div>
      </div>
    </BaseModal>
  );
}

export default ThanksModal;
