import * as React from "react";
import { ResultItemType } from "../../../types";
import { ColumnType } from "antd/es/table";
import correctIcon from "../../../assets/imgs/correctIcon.png";
import failIcon from "../../../assets/imgs/failIcon.png";
import { formatTime } from "../../../utils";

function useReviewPointColumn(): ColumnType<ResultItemType>[] {
  return React.useMemo(
    (): ColumnType<ResultItemType>[] => [
      {
        title: "Số thứ tự",
        key: "stt",
        render: (value, row, index: number) => index + 1,
      },
      {
        title: "Thời điểm",
        dataIndex: "time",
        render: (value) => formatTime(value),
      },
      {
        title: "Kết quả",
        dataIndex: "result",
        render: (value) =>
          value ? (
            <img src={correctIcon} height="20" alt="" />
          ) : (
            <img src={failIcon} height="20" alt="" />
          ),
      },
      {
        title: "Điểm",
        dataIndex: "point",
      },
    ],
    []
  );
}

export default useReviewPointColumn;
