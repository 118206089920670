import { atom, selector } from "recoil";
import { VideoType } from "../types";

interface videoListAtomType {
  category: string;
  videos: VideoType[]
  videoMap: Record<string, VideoType>;
  total: number;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  filter: {
    search?: string;
    sortBy?: string;
  };
}

const videoListAtom = atom<videoListAtomType>({
  key: "videoListAtom",
  default: {
    category: "",
    videos: [],
    videoMap: {},
    total: 0,
    loading: false,
    currentPage: 1,
    pageSize: 12,
    filter: {
      sortBy: "latest",
      search: "",
    },
  },
});

const availableVideosSelector = selector({
  key: "availableVideosSelector",
  get: ({ get }) => {
    const { videoMap, currentPage, pageSize, filter } = get(videoListAtom);
    // filter and sort
    let filteredVideos = Object.values(videoMap);
    if (filter.search) {
      filteredVideos = filteredVideos.filter((video) =>
        video.name.toLowerCase().includes(filter.search?.toLowerCase()!)
      );
    }
    if (filter.sortBy) {
      // filteredVideos.sort((a, b) => filter.sortBy === 'latest' ? b.createdAt - a.createdAt : a.createdAt - b.createdAt)
      if (filter.sortBy === "latest") filteredVideos = filteredVideos.reverse();
    }
    // split by page number
    const availableVideos = filteredVideos.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    );

    return {
      videos: availableVideos,
      total: filteredVideos.length,
    };
  },
});

export default videoListAtom;

export { availableVideosSelector };
