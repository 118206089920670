import * as React from "react";
import { Button, Collapse, Form, Popconfirm, Table } from "antd";
import PrimaryBtn from "../../Button/PrimaryBtn";
import BaseModal, { BaseModalProps } from "../BaseModal";
import "../styles.scss";
import useReviewPointColumn from "./useReviewPointColumn";
import { ResultItemType, UserType, VideoType } from "../../../types";
import { useRecoilState, useRecoilValue } from "recoil";
import resultRecordAtom, {
  sumPointSelector,
} from "../../../recoil/resultRecordAtom";
import userInfoAtom from "../../../recoil/userInfoAtom";

const { Panel } = Collapse;

interface Props extends BaseModalProps {
  data: VideoType
  onSubmit: (data: UserType) => void;
  onReWatch: () => void;
  isAnsweredQuestion: boolean;
}

function ReviewPointModal(props: Props) {
  const { onSubmit, onReWatch, isAnsweredQuestion, data } = props;
  const [activeKey, setActiveKey] = React.useState<string[] | string>([]);
  const [resultRecord] = useRecoilState(resultRecordAtom);
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [form] = Form.useForm();

  const configColumn = useReviewPointColumn();

  const textPoint = data?.is_sponsor ? 'đồng' : 'điểm'

  const { resultPoint, quizzesPoint, sumPoint } =
    useRecoilValue(sumPointSelector);

  const handleSubmit = async () => {
    const fieldsValue = await form.validateFields();
    setUserInfo({ ...userInfo, ...fieldsValue });
    onSubmit(fieldsValue);
  };

  const submitBtn = isAnsweredQuestion ? (
    <Popconfirm
      placement="top"
      title="Câu hỏi này đã từng được tính điểm. Nếu bạn Đồng ý điểm mới sẽ được ghi đè!"
      onConfirm={handleSubmit}
      okText="Đồng ý"
      cancelText="Huỷ"
    >
      <PrimaryBtn>Báo điểm</PrimaryBtn>
    </Popconfirm>
  ) : (
    <PrimaryBtn onClick={handleSubmit}>Báo điểm</PrimaryBtn>
  );

  return (
    <BaseModal title="Bạn đã xem xong nội dung" footer={submitBtn} {...props}>
      <div className="reviewPointContainer">
        <div className="pointWrapper">
          <div>Tổng điểm đạt được là:</div>
          <div className="point">{sumPoint} {textPoint}</div>
          <Collapse
            ghost
            expandIcon={() => null}
            activeKey={activeKey}
            onChange={(data) => setActiveKey(data)}
          >
            <Panel
              header={
                <div className="detailHeader">
                  <Button type="link">
                    {activeKey.length ? "Ẩn chi tiết" : "Xem chi tiết"}
                  </Button>
                </div>
              }
              key="1"
            >
              <Table<ResultItemType>
                bordered
                columns={configColumn}
                dataSource={resultRecord.result}
                pagination={false}
              />
            </Panel>
          </Collapse>
          <div style={{ margin: "10px 0" }}>
            Kết quả:{" "}
            <span
              className="point"
              style={{ fontSize: 14 }}
            >{`${resultPoint} ${textPoint} + ${quizzesPoint} ${textPoint}`}</span>{" "}
            (Trả lời câu hỏi Quiz)
          </div>
        </div>
        <div className="methodContent">
          Bạn có thể xem lại hoặc báo điểm.
          <Button type="link" onClick={onReWatch}>
            Xem lại video
          </Button>
        </div>
      </div>
    </BaseModal>
  );
}

export default ReviewPointModal;
