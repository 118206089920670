import { Col, Row } from "antd";
import { VideoType } from "../../../../types";
import VideoCollectionItem from "./VideoCollectionItem";
import "./styles.scss";
import { mobileAndTabletCheck } from "../../../../utils";
import HorizontalScrolling from "../../../../components/Scroll/HorizontalScrolling";

interface Props {
  videos: VideoType[];
}

const VideoItem = ({ video }: { video: VideoType; itemId: string }) => (
  <div className="videoCollectionItemWrapper">
    <VideoCollectionItem videoDetail={video} />
  </div>
);

function VideoCollection(props: Props) {
  const { videos } = props;
  // const publicVideos = videos.filter((v) => !("private" in v && v.private));
  const publicVideos = videos;

  if (mobileAndTabletCheck)
    return (
      <Row className="videoCollection px-3" gutter={[10, 10]}>
        {publicVideos?.map((video) => (
          <Col xs={24} key={video.video}>
            <VideoCollectionItem videoDetail={video} />
          </Col>
        ))}
      </Row>
    );

  const renderItem = (item: VideoType) => <VideoItem key={item.id} itemId={item.id!} video={item} />

  return <>
    <HorizontalScrolling
      className="videoCollection"
      data={publicVideos}
      renderItem={renderItem} />
  </>
}

export default VideoCollection;
