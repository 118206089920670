import React from 'react'
import { SvgIconProps } from './type'

const Next: React.FC<SvgIconProps> = ({ height = 12, width = 8, color = '#D32F2F' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.33329 6.00345L1.70256 11.8333L0.666626 10.76L5.26142 6.00345L0.666626 1.24156L1.70256 0.166584L7.33329 6.00345Z" fill={color} />
    </svg>
  )
}

export default Next