import React, { useEffect } from "react";
import { Button, Row } from "antd";
import _ReactPlayer, { ReactPlayerProps } from "react-player";
import { ICustomer, UserType, VideoType } from "../../../../types";
import playImg from "../../../../assets/imgs/play-btn.png";
import pauseImg from "../../../../assets/imgs/pause-btn.png";
import { SyncOutlined } from "@ant-design/icons";
import "./styles.scss";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import pointRuleSelector from "../../../../recoil/pointRuleSelector";
import resultRecordAtom, {
  sumPointSelector,
} from "../../../../recoil/resultRecordAtom";
import useModal from "../../../../components/useHook/useModal";
import ReviewPointModal from "../../../../components/Modal/ReviewPointModal";
import {
  formatPoint,
  generateDetailVideo,
  mobileAndTabletCheck,
} from "../../../../utils";
import { getQuestionStatus, postResultRecord } from "../../../../apis";
import detailVideoAtom from "../../../../recoil/detailVideoAtom";
import ThanksUserCodeModal from "../../../../components/Modal/ThanksUserCodeModal";
import { ACCEPTED_TOLERANCE } from "../../../../constants/constants";
import ReceivePrizeBtn from "../../../../components/Button/ReceivePrizeBtn";
import ShareBtn from "../../../../components/Button/ShareBtn";
import userInfoAtom from "../../../../recoil/userInfoAtom";
import QuizzesModal from "../../../../components/Modal/QuizzesModal";
import appModalAtom from "../../../../recoil/appModalAtom";
import { ModalType } from "../../../../types/enum";
import imagesAssets from "../../../../assets/imgs";
import BoxUsedPoint from "./BoxUsedPoint";
import useInfoProfile from "../../../../components/useHook/useInfoProfile";
import MainButton from "../../../../components/Button/MainButton";

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

let checkedAnswers: Record<number, boolean> = {}; // store answer time that recorded to avoid count many times

const Video: React.FC = () => {
  const { handleRefreshProfile } = useInfoProfile();
  const [playing, setPlaying] = React.useState(false);
  const pointMessageRef = React.useRef();
  const reactPlayerRef = React.useRef<any>();
  const quizIndexRef = React.useRef<number>(0);

  const pointRule = useRecoilValue(pointRuleSelector);
  const [detailVideo, setDetailVideo] = useRecoilState(detailVideoAtom);
  const setAppModal = useSetRecoilState(appModalAtom);
  const [resultRecord, setResultRecord] = useRecoilState(resultRecordAtom);
  const [userInfo] = useRecoilState(userInfoAtom);
  const [isAnswered, setIsAnswered] = React.useState(false);
  const [exchangePoints, setExchangePoints] = React.useState(!!userInfo?.email);

  const {
    id: videoId,
    video,
    name,
    questions,
    activeQuestionIndex,
    quizzes,
    products = [],
    is_sponsor,
    customer = {} as ICustomer,
  } = detailVideo || {};
  const question = questions?.[activeQuestionIndex!];

  const textPoint = is_sponsor ? "đồng" : "điểm";

  const resetWatchingSession = React.useCallback(
    (forceRandomQuestionIndex?: boolean) => {
      if (forceRandomQuestionIndex) {
        setDetailVideo((detailVideo) =>
          detailVideo ? generateDetailVideo(detailVideo) : detailVideo
        );
      }
      setPlaying(false);
      setResultRecord({ result: [], quizzes: [] });
      quizIndexRef.current = 0;
    },
    [setDetailVideo, setResultRecord]
  );

  useEffect(() => {
    if (userInfo?.email && videoId && questions?.[activeQuestionIndex!]?.id) {
      getQuestionStatus({
        videoId: videoId,
        questionId: questions[activeQuestionIndex!].id!,
      }).then((result) => {
        if (result.message === "Question Answered") setIsAnswered(true);
        else setIsAnswered(false);
      });
    }
  }, [videoId, activeQuestionIndex, userInfo]);

  React.useEffect(() => {
    resetWatchingSession();
  }, [resetWatchingSession]);

  const {
    visible: reviewPointVisible,
    showModal: showReviewPointModal,
    closeModal: closeReviewPointModal,
  } = useModal();

  const {
    visible: thanksUserCodeVisible,
    showModal: showThanksUserCodeModal,
    closeModal: closeThanksUserCodeModal,
  } = useModal();

  const {
    visible: quizzesVisible,
    showModal: showQuizzesModal,
    closeModal: closeQuizzesModal,
  } = useModal();

  const { sumPoint } = useRecoilValue(sumPointSelector);

  const handleReceivePrize = () => {
    const notifier = pointMessageRef.current as any;

    if (!playing) {
      notifier?.setError(`Ấn xem video để có thể nhận thưởng`);
      return;
    }

    const currentTime = reactPlayerRef.current?.getCurrentTime();
    const { answers } = question || {};
    const isCorrectTime = answers?.find(
      (answerTime: any) =>
        currentTime >= answerTime - ACCEPTED_TOLERANCE &&
        currentTime <= answerTime + ACCEPTED_TOLERANCE
    );
    if (isCorrectTime! >= 0) {
      if (checkedAnswers[isCorrectTime!]) {
        // this answer is counted --> ignore
        notifier?.setError(`Kết quả đã được tính`);
        return;
      }
      checkedAnswers[isCorrectTime!] = true;
    }

    const point = formatPoint(
      isCorrectTime! >= 0
        ? pointRule.addResultNumber
        : pointRule.subtractResultNumber
    );

    // notify
    if (point > 0)
      notifier?.setSuccess(
        `Thật tuyệt! Bạn vừa được thêm <b>+${point} ${textPoint}</b>`
      );
    else
      notifier?.setError(
        `Sai rồi! Bạn vừa bị trừ <b>${point} ${textPoint}</b>`
      );

    // set state
    setResultRecord({
      ...resultRecord,
      result: [
        ...resultRecord.result,
        { time: currentTime, result: isCorrectTime! >= 0, point: point },
      ],
    });
  };

  const handleFinish = () => {
    setPlaying(false);
    checkedAnswers = {};
    reactPlayerRef.current.seekTo(0);
    showReviewPointModal();
  };

  const handleSubmit = async (data: UserType) => {
    // submit api
    await postResultRecord({
      video: {
        id: videoId!,
        name: name!,
        url: video!,
      },
      question: {
        id: question?.id!,
        word: question?.word!,
      },
      url: video!,
      point: sumPoint,
      result: resultRecord.result,
      quizzes: resultRecord.quizzes,
    });

    closeReviewPointModal();
    showThanksUserCodeModal();
    // reset
    handleRefreshProfile();
    resetWatchingSession(true);
  };

  const renderWarningMessage = () => (
    <>
      {isAnswered && (
        <>
          <MainButton
            shape="round"
            icon={
              <SyncOutlined className="text-[#ffffff] hover:text-[#1F91FA]" />
            }
            className="flex justify-center content-center items-center mr-3"
            onClick={() => resetWatchingSession(true)}
          >
            Đổi câu hỏi
          </MainButton>
        </>
      )}
    </>
  );

  const renderNoteVideo = () => {
    if (exchangePoints) {
      return (
        <div className="videoNoteReward md:block md:text-left flex items-center">
          <span className="md:hidden">{renderWarningMessage()}</span> Bạn nhận
          được{" "}
          <span className="highlights">
            {formatPoint(pointRule.addResultNumber)}
          </span>{" "}
          {textPoint}/mỗi lượt bấm đúng và{" "}
          <span className="highlights">
            {formatPoint(pointRule.addQuizNumber)}
          </span>{" "}
          {textPoint} cho mỗi câu trả lời quiz đúng.
        </div>
      );
    }

    return <></>;
  };

  const renderQuestionMessage = () => (
    <b className="answerKeyword">{question?.word}</b>
  );

  const handlePlayClick = () => {
    if (userInfo?.email) {
      setPlaying((prev) => !prev);
    } else {
      setAppModal({ mode: ModalType.signIn });
    }
  };

  const handleVideoProgress = (state: any) => {
    if (!quizzes?.[quizIndexRef.current]) return;
    const { playedSeconds } = state;
    if (quizzes?.[quizIndexRef.current].show_at! <= playedSeconds) {
      setPlaying(false);
      showQuizzesModal();
    }
  };

  const renderDeepViewBtn = () => {
    return (
      <div className="boxDeepview">
        <span className="content  md:flex">{renderQuestionMessage()}</span>
        <ReceivePrizeBtn
          onClick={handleReceivePrize}
          ref={pointMessageRef}
          className="receivePrizeBtn"
        />
      </div>
    );
  };

  return (
    <Row className="videoWrapper w-full">
      <div className="videoContainer">
        <div className="playerWrapper">
          <ReactPlayer
            url={video}
            playing={playing}
            onEnded={handleFinish}
            width={"100%"}
            height={"100%"}
            className="reactPlayer"
            // controls
            ref={reactPlayerRef}
            config={{
              youtube: {
                playerVars: {
                  controls: 0,
                  showinfo: 0,
                  // rel: 0,
                  cc_load_policy: 1,
                  cc_lang_pref: "vi",
                },
              },
            }}
            onProgress={handleVideoProgress}
          />
          {/* trick: due to cannot hide control player of youtube, then make an overlay above video to handle play/pause */}
          <div className="playerButtonOverlay" onClick={handlePlayClick}>
            {!mobileAndTabletCheck && (
              <img
                className="playPauseIcon"
                src={playing ? pauseImg : playImg}
                alt=""
              />
            )}
          </div>
        </div>

        <div className="hidden md:block">
          <div className="flex infoVideoMobile">
            <img
              className="logoChannelMobile"
              src={customer.avatar ?? imagesAssets.avatar}
              alt="name"
            />
            <div>
              <div className="videoName">{name}</div>
              <div className="channelName">
                {customer.display_name ?? "No Name"}
              </div>
            </div>
          </div>

          <div className="flex boxDeepViewMobile">{renderDeepViewBtn()}</div>

          <div className="noteMobile">{renderNoteVideo()}</div>

          <div className="flex my-5 justify-between px-3">
            <span className="hidden md:block">{renderWarningMessage()}</span>
            <ShareBtn />
          </div>

          <div className="promotion boxPointMobile">
            <BoxUsedPoint exchangePoints={exchangePoints} />
          </div>
        </div>

        <div className="promotion md:hidden">
          <BoxUsedPoint exchangePoints={exchangePoints} />
          {renderDeepViewBtn()}
        </div>
      </div>
      {!mobileAndTabletCheck && (
        <div className="videoNameWrapper">
          <div className="videoName">
            {name}
            {renderNoteVideo()}
          </div>
          <ShareBtn />
        </div>
      )}

      <div className="infoChannel flex md:hidden">
        <img
          className="channelLogo"
          src={customer.avatar ?? imagesAssets.avatar}
          alt="name"
        />
        <div className="channelName">{customer.display_name ?? "No Name"}</div>
      </div>

      <ReviewPointModal
        open={reviewPointVisible}
        onReWatch={() => {
          closeReviewPointModal();
          resetWatchingSession(true);
        }}
        onSubmit={handleSubmit}
        isAnsweredQuestion={isAnswered}
        data={detailVideo ?? ({} as VideoType)}
      />

      <ThanksUserCodeModal
        open={thanksUserCodeVisible}
        onClose={closeThanksUserCodeModal}
      />
      {quizzesVisible && (
        <QuizzesModal
          open={quizzesVisible}
          quiz={quizzes?.[quizIndexRef.current]!}
          quizIndex={quizIndexRef.current}
          onClose={() => {
            closeQuizzesModal();
            quizIndexRef.current++;
            setPlaying(true);
          }}
        />
      )}
    </Row>
  );
};

export default Video;
