import banner from './banner.png'
import facebookShare from './facebook-share.png'
import avatar from './avatar.png'
import promotion from './promotion.png'
import nextIcon from './nextIcon.png'
import previousIcon from './previousIcon.png'
import product from './product.png'
import thanks from './thanks.png'
import empty from './empty.png'
import item from './item.png'
import home from './home.png'
import homeIn from './homeIn.png'
import purchase from './purchase.png'
import video from './video.png'
import emptyVideo from './emptyvideo.png'
import feedback from './feedback.png'
import help from './help.png'
import videoIn from './videoIn.png'


export const imagesAssets = {
  banner,
  avatar,
  facebookShare,
  promotion,
  nextIcon,
  previousIcon,
  product,
  thanks,
  empty,
  item,
  home,
  homeIn,
  purchase,
  video,
  emptyVideo,
  feedback,
  help,
  videoIn
}

export default imagesAssets