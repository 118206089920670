import { Dropdown, Menu } from 'antd';
import React, { useState } from 'react'
import { useRecoilState, useSetRecoilState } from 'recoil';

import "./styles.scss";

import deepviewButton from "../../assets/imgs/deepviewButton.png";
import userInfoAtom from '../../recoil/userInfoAtom';
import { showFullName } from '../../utils/infoRender';
import Svg from '../Svg';
import appModalAtom from '../../recoil/appModalAtom';
import { ModalType } from '../../types/enum';
import BaseModal from '../Modal/BaseModal';
import { formatCurrency, formatNumber, formatShowCurrency } from '../../utils/format';
import { formatPoint } from '../../utils';
import useCopyToClipboard from '../useHook/useCopyToClipboard';

const UserInfo: React.FC = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const setAppModal = useSetRecoilState(appModalAtom);
  const [value, copy] = useCopyToClipboard()

  const [visible, setVisible] = useState(false)

  const [openUserModal, setOpenUserModal] = useState(false)

  const handleOpen = (flag: boolean) => {
    setVisible(flag)
  }

  const handleClickHistory = () => {
    setVisible(false)
    setAppModal({ mode: ModalType.redeemHistory })
  }

  const handleClickPurchasedVideos = () => {
    setVisible(false)
    setAppModal({ mode: ModalType.purchasedVideos })
  }

  const handleClickCopy = () => {
    copy(userInfo?.refCode ?? '')
  }

  const menu = (
    <Menu className='infoMenu'>
      <div className='item'>
        <div className='infoUserWrapper'>
          <img className='userImage' src={deepviewButton} alt="" />
          <div className='infoUser nameUser'>
            {showFullName(userInfo)}
            <span className='email'>{userInfo.email}</span>
          </div>
        </div>
      </div>
      {userInfo?.my_refcode
        ? <>
          <div className='item flex items-center'>
            <div>Mã giới thiệu:</div>
            <div className='flex align-middle content-center items-center text-[#1F91FA] ml-2 cursor-pointer' onClick={handleClickCopy}>
              {userInfo?.my_refcode} <span className='ml-2'><Svg.Copy /></span>
            </div>
          </div></>
        : <></>
      }

      <div className='item pointWrapper'>
        <div className='flex flex-col'>
          <div>Số dư khả dụng:</div>
          <div className='point md:mt-3 mt-1'>{formatShowCurrency(userInfo?.amount ?? 0)}</div>
        </div>
        <div className='mt-3'>
          <div>Điểm tích lũy:</div>
          <div className='point md:mt-3 mt-1'>{formatShowCurrency(userInfo?.points ?? 0, true)}</div>
        </div>
      </div>

      <div className='item finally' >
        {/* <div className='item-click sp-24' onClick={handleClickPurchasedVideos}>
          <Svg.Withdraw /> <span className='click-title'>Yêu cầu rút tiền</span>
        </div> */}
        <div className='item-click sp-24' onClick={handleClickHistory}>
          <Svg.History /> <span className='click-title'>Lịch sử mua hàng</span>
        </div>
        <div className='item-click' onClick={() => setUserInfo({})}>
          <Svg.Logout /> <span className='click-title'>Đăng xuất</span>
        </div>
      </div>
    </Menu >
  );

  const handleUserModal = () => {
    setOpenUserModal(true)
  }

  const handleCancelOrder = () => {
    setOpenUserModal(false)
  }

  return (
    <>
      <div className="block md:hidden">
        <Dropdown open={visible} onOpenChange={handleOpen} trigger={['click']} overlay={menu} placement="bottomRight">
          <div className="userHeader">
            <div className='title nameUser'>{showFullName(userInfo)}</div>

            <img src={deepviewButton} height={25} alt="" />
          </div>
        </Dropdown>
      </div>
      <div className='hidden md:block'>
        <img onClick={handleUserModal} src={deepviewButton} height={25} alt="" />
      </div>
      <BaseModal
        className="userInfoModal"
        width={'80%'}
        open={openUserModal}
        onCancel={handleCancelOrder}
      >
        <div className="userInfoWrapper">
          {menu}
        </div>
      </BaseModal>
    </>
  )
}

export default UserInfo