import * as React from "react";
import { Button } from "antd";
import { ButtonProps } from "antd/lib/button";
import "./styles.scss";

function SecondaryBtn(props: ButtonProps) {
  return (
    <Button type="primary" ghost {...props}>
      {props.children || "Submit"}
    </Button>
  );
}

export default SecondaryBtn;
