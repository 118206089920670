import { Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import { apis, CouponType, MyCouponType } from '../../../apis'
import CancelButton from '../../Button/CancelButton'
import MainButton from '../../Button/MainButton'

type Props = {
  onBack: () => void
}

enum ETabVoucher {
  useVoucher,
  redeem
}


enum EItemCoupon {
  myCoupons,
  reward
}

const styleActive = 'bg-[#E2EFFC] text-[#1F91FA]'
const styleDefault = 'bg-[#F9F9F9] text-[#606060]'

const Voucher: React.FC<Props> = ({ onBack }) => {
  const [tab, setTab] = useState(ETabVoucher.useVoucher)

  const [myCoupons, setMyCoupons] = useState<MyCouponType[]>([])
  const [coupons, setCoupons] = useState<CouponType[]>([])

  const handleSelectTabVoucher = () => {
    setTab(ETabVoucher.useVoucher)
  }

  const handleSelectTabRedeem = () => {
    setTab(ETabVoucher.redeem)
  }

  const handlePurchaseCoupon = async (item: CouponType) => {
    console.log('item', item)
    try {
      const response = await apis.exchangeCoupon(item._id)
      console.log('response', response)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleSelectCoupon = async (item: MyCouponType) => {
    try {
      console.log('handleSelectCoupon', item)
    } catch (error) {
      console.log('error', error)
    }
  }

  const renderItemCoupons = (item: CouponType) => {
    const disableItem = false;

    const disabledStyle = disableItem ? 'disable' : ''
    const priceStyle = disableItem ? 'text-[#939393]' : 'text-[#1C62B9]'
    const dateStyle = disableItem ? 'text-[#939393]' : 'text-[#D32F2F]'
    const actionStyle = disableItem ? 'bg-[#F8F8F8]' : ''
    const actionNameStyle = disableItem ? 'text-[#CCCCCC]' : 'text-[#1F91FA]'

    const textAction = 'Đổi'

    return (
      <div className={`item-voucher shadow-md flex w-full cursor-pointer ${disabledStyle}`}>
        <div className='flex-1 flex flex-col align-middle justify-center pl-4 py-4'>
          <p className={`text-base font-semibold ${priceStyle}`}>{item.description}</p>
          <p className={`text-sm ${dateStyle}`}>Thời hạn đến ngày: {item.valid_for}</p>
        </div>
        <div className={`w-20 border-l border-dashed flex items-center justify-center rounded-r ${actionStyle}`}>
          <span onClick={() => handlePurchaseCoupon(item)} className={actionNameStyle}>{textAction}</span>
        </div>
      </div>
    )
  }

  const renderMyCoupons = (item: MyCouponType) => {
    const disableItem = false;

    const disabledStyle = disableItem ? 'disable' : ''
    const priceStyle = disableItem ? 'text-[#939393]' : 'text-[#1C62B9]'
    const dateStyle = disableItem ? 'text-[#939393]' : 'text-[#D32F2F]'
    const actionStyle = disableItem ? 'bg-[#F8F8F8]' : ''
    const actionNameStyle = disableItem ? 'text-[#CCCCCC]' : 'text-[#1F91FA]'

    const textAction = 'Chọn'

    return (
      <div className={`item-voucher shadow-md flex w-full cursor-pointer ${disabledStyle}`}>
        <div className='flex-1 flex flex-col align-middle justify-center pl-4 py-4'>
          <p className={`text-base font-semibold ${priceStyle}`}>{item.description}</p>
          <p className={`text-sm ${dateStyle}`}>Thời hạn đến ngày: {item.expired_at}</p>
        </div>
        <div className={`w-20 border-l border-dashed flex items-center justify-center rounded-r ${actionStyle}`}>
          <span onClick={() => handleSelectCoupon(item)} className={actionNameStyle}>{textAction}</span>
        </div>
      </div>
    )
  }

  const renderEmpty = () => {
    return <div className='flex justify-center align-middle'>
      <div className='my-7 '>Bạn chưa có mã giảm giá nào. <span onClick={handleSelectTabRedeem} className='text-[#D32F2F] cursor-pointer'>Đổi ngay</span></div>
    </div>
  }

  const renderMyVouchers = () => {
    return (
      <div className='my-2 grid grid-cols-2'>
        {myCoupons.map((item) => renderMyCoupons(item))}
      </div>
    )
  }

  const renderVoucher = () => {
    return (
      <>
        <p className='text-[#939393]'>Áp dụng tối đa: 1</p>
        <div className=''>
          {myCoupons && myCoupons.length > 0 ? renderMyVouchers() : renderEmpty()}
        </div>
      </>
    )
  }

  const renderRedeemVoucher = () => {
    return (
      <div className='grid grid-cols-2 gap-4'>
        {coupons.map((item) => renderItemCoupons(item))}
      </div>
    )
  }

  const renderContent = () => {
    return tab === ETabVoucher.useVoucher ? renderVoucher() : renderRedeemVoucher()
  }

  useEffect(() => {
    const getCouponsApi = async () => {
      try {
        const myCouponsResponse = await apis.myCoupons()
        const responsiveCoupons = await apis.coupons()
        setMyCoupons(myCouponsResponse?.coupons)
        setCoupons(responsiveCoupons?.coupons)
      } catch (error) {
        console.log('error', error)
      }
    }

    getCouponsApi()
  }, [])


  return (
    <div>
      <div className="title">Mã giảm giá</div>
      <div className='flex'>
        <div onClick={handleSelectTabVoucher} className={`flex-1 py-4 text-center cursor-pointer flex justify-center rounded-l-[1rem] ${tab === ETabVoucher.useVoucher ? styleActive : styleDefault}`}>
          Áp dụng mã giảm giá <div className='ml-3 bg-[#D32F2F] w-6 h-6 rounded-full text-white flex justify-center content-center align-middle items-center'>1</div>
        </div>
        <div onClick={handleSelectTabRedeem} className={`flex-1 py-4  text-center cursor-pointer rounded-r-[1rem] ${tab === ETabVoucher.redeem ? styleActive : styleDefault}`}>
          Đổi mã giảm giá
        </div>
      </div>
      <div className='pt-5'>
        {renderContent()}
      </div>
      <div className='mt-5 mb-5 flex justify-end'>
        <CancelButton onClick={onBack} className='mr-3 min-w-[120px]'>Trở lại</CancelButton>
        <MainButton className='min-w-[120px]' disabled>Tiếp tục</MainButton>
      </div>
    </div>
  )
}

export default Voucher