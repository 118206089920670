import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil';
import PointsRedemptionHistory from '../components/Modal/PointsRedemptionHistory';
import ThanksModal from '../components/Modal/Thanks';
import UserModal from "../components/Modal/UserModal";
import SearchModal from "../components/Modal/SearchModal";
import appModalAtom from '../recoil/appModalAtom';
import { ModalType } from '../types/enum';
import PurchasedVideo from '../components/Modal/PurchasedVideo';
import PurchaseVideoModal from '../components/Modal/PurchaseVideoModal';
import appConfigAtom from '../recoil/appConfigAtom';
import LoadingFullScreen from '../components/Loading/LoadingFullScreen';
import userInfoAtom from '../recoil/userInfoAtom';
import useInfoProfile from '../components/useHook/useInfoProfile';
import { PurchaseVideoModalType } from '../types';

const AppTrigger = () => {
  const { handleRefreshProfile } = useInfoProfile()

  const [appModal, setAppModal] = useRecoilState(appModalAtom);
  const [appConfig] = useRecoilState(appConfigAtom);

  const handleCloseModal = () => {
    if (appModal?.onCancel) {
      appModal?.onCancel()
    } else {
      setAppModal({ mode: ModalType.close })
    }
  }

  useEffect(() => {
    handleRefreshProfile()
  }, [])


  return (
    <>
      <UserModal data={appModal?.data} open={[ModalType.signIn, ModalType.signUp].includes(appModal.mode)} onCancel={handleCloseModal} />
      <ThanksModal open={appModal.mode === ModalType.thanks} onCancel={handleCloseModal} />
      <PointsRedemptionHistory open={appModal.mode === ModalType.redeemHistory} onCancel={handleCloseModal} />
      <SearchModal open={appModal.mode === ModalType.search} onCancel={handleCloseModal} />
      <PurchasedVideo open={appModal.mode === ModalType.purchasedVideos} onCancel={handleCloseModal} />
      <PurchaseVideoModal
        open={appModal.mode === ModalType.purchaseVideo}
        onCancel={handleCloseModal} data={appModal.data}
        extraPurChase={(appModal as PurchaseVideoModalType).extraPurChase}
        extraCloseViewMore={(appModal as PurchaseVideoModalType).extraCloseViewMore}
      />

      {/* loading */}
      {appConfig.loading ? <LoadingFullScreen /> : <></>}
    </>
  )
}

export default AppTrigger