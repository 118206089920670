import React from 'react'

type Props = {
  image: string
  imageActive: string
  name: string
  isActive?: boolean
  onClick?: () => void
}

const CategoryItem: React.FC<Props> = ({ image, imageActive, name, onClick, isActive = false }) => {
  return (
    <div className={`pl-5 py-2 flex items-center cursor-pointer ${isActive ? 'bg-[#E5E5E5]' : ''}`} onClick={onClick}>
      <span className="w-8"><img src={isActive ? imageActive : image} width={24} height={24} alt="item" /></span>
      <span className="ml-3 flex-1 pr-2 text-[#030303] font-normal text-lg truncate ">{name}</span>
    </div>
  )
}

export default CategoryItem