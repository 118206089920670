import { Col, Row, Spin } from "antd";
import { useRecoilState } from "recoil";
import InfiniteScroll from 'react-infinite-scroller';
import "./styles.scss";
import VideoCollectionItem from "../VideoQuiz/components/VideoCollection/VideoCollectionItem";
import { mobileAndTabletCheck } from "../../utils";

import Slide from "./Slide";
import Categories from "./Categories";
import videosAtom from "../../recoil/videosAtom";
import { useEffect, useState } from "react";
import { apis, IVideoParams } from "../../apis";
import { brandMyVideo, brandTotal, categoryTotal } from "./constant";
import videoListAtom from "../../recoil/videoListAtom";
import { VideoType } from "../../types";
import useInfoProfile from "../../components/useHook/useInfoProfile";
import imagesAssets from "../../assets/imgs";
import { useParams } from "react-router-dom";

const ITEM_PER_PAGE = 12

interface IParams {
  category?: string
}

function HomePage() {
  const params = useParams<IParams>()
  console.log('params', params)
  const { category } = params ?? {}
  const { userInfo, handleRefreshProfile } = useInfoProfile()
  const [videoList] = useRecoilState(videosAtom);
  const [videoCommonList, setVideoCommonList] = useRecoilState(videoListAtom);
  const { filter } = videoList
  const { loading, videos } = videoCommonList

  const [currentPage, setCurrentPage] = useState(1)
  const [hasMore, setHasMore] = useState(false)

  const getApi = async (page: number = 1) => {
    try {
      setHasMore(false)
      const paramSearch: IVideoParams = {
        page_limit: ITEM_PER_PAGE,
        page: page
      }
      let isAuth = false

      if (filter?.category && filter?.category !== categoryTotal.slug) {
        paramSearch.category = filter?.category
      }

      if (category) {
        paramSearch.category = category
      }

      if (userInfo?.customer_id) {
        paramSearch.customer_id = userInfo?.customer_id
      }

      if (filter?.search) {
        paramSearch.q = filter?.search
      }

      // brand
      if (filter?.customerId) {
        if (filter?.customerId === brandTotal._id) {
          // paramSearch.customer_id = undefined
        } else if (filter?.customerId === brandMyVideo._id) {
          paramSearch.purchased = true
          isAuth = true
        } else {
          // paramSearch.customer_id = filter?.customerId
        }
      }

      const response = await apis.getVideoList(paramSearch, isAuth)

      setVideoCommonList((value) => {
        const videosArr = [...value.videos, ...response?.videos]

        const videoObjectMap = videosArr.reduce(
          (videoMap: Record<string, VideoType>, video: VideoType) => {
            videoMap[video.id!] = video;
            return videoMap;
          },
          {}
        )

        return {
          ...value,
          loading: false,
          videos: videosArr,
          videoMap: { ...videoObjectMap },
        }
      })

      if (response?.videos?.length < ITEM_PER_PAGE) {
        setHasMore(false)
      } else {
        setCurrentPage(page + 1)
        setHasMore(true)
      }
    } catch (error) {
      console.log('error getApi', error)
    }
  }

  const fetchMoreData = async () => {
    if (!hasMore || videos.length === 0) {
      return
    }

    await getApi(currentPage)
  }

  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );

  const renderEmpty = () => {
    return (
      <div className="flex pt-8 justify-center items-center w-full h-full">
        <div>
          <img alt="logo" className="w-full max-w-[400px]" src={imagesAssets.emptyVideo} />
          <p className="text-center mt-8 mb-6 font-semibold">Danh mục này chưa cập nhật video</p>
          <p className="text-center">Hãy thử đến với các danh mục khác</p>
        </div>
      </div>
    )
  }

  const renderMain = () => {
    return (
      <Col className="h-full" span={mobileAndTabletCheck ? 24 : 20}>
        {category ? <></> : <><div className="fixed z-10 h-[62px] w-full"><Categories /></div><div className="mt-[62px]" /></>}
        {
          loading
            ? (
              <div className="globalLoadingContainer">
                <Spin />
              </div>
            ) :
            <>
              {videos.length === 0 ? renderEmpty() : <></>}
              {videos.length > 0 ? <InfiniteScroll
                loadMore={fetchMoreData}
                hasMore={hasMore}
                loader={loader}
              >
                <Row className="p-4" gutter={[20, 20]}>
                  {videos.length > 0 ? videos.map((video) => (
                    <Col key={video.id} className="gutter-row" span={24} md={8} lg={6}>
                      <VideoCollectionItem videoDetail={video} />
                    </Col>
                  )) : <></>}
                </Row>
              </InfiniteScroll> : <></>}

            </>
        }
      </Col>
    )
  }

  useEffect(() => {
    setVideoCommonList((value) => ({
      ...value,
      videos: [],
      loading: true,
    }))

    getApi(1)
  }, [filter?.category, filter?.customerId, userInfo?.customer_id, filter?.search])

  return (
    <div className="homePageContainer">
      <Row className="mt-[62px]">
        <Slide />
        {renderMain()}
      </Row>
    </div>
  );
}

export default HomePage;
