import React, { forwardRef } from "react";
import classnames from "classnames";
import { message as antMessage } from "antd";
import "./styles.scss";
import { mobileAndTabletCheck } from "../../utils";
import soundsAssets from "../../assets/sounds";

interface Props {
  className?: string;
}

function PointMessage(props: Props, ref: any) {
  const { className } = props;
  const [message, setMessage] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(true);
  const audioRef = React.useRef<any>();

  const handlePlaySound = (srcSound: string) => {
    audioRef.current.volume = 0.2;
    audioRef.current.src = srcSound;
    audioRef.current.play();
  };

  const handleShowMessage = (message: string, isSuccess?: boolean) => {
    if (mobileAndTabletCheck) {
      Boolean(isSuccess)
        ? antMessage.success(<span dangerouslySetInnerHTML={{ __html: message }} />)
        : antMessage.error(<span dangerouslySetInnerHTML={{ __html: message }} />);
      handlePlaySound(isSuccess ? soundsAssets.success : soundsAssets.error);
    } else {
      setIsSuccess(Boolean(isSuccess));
      setMessage(message);
      handlePlaySound(isSuccess ? soundsAssets.success : soundsAssets.error);
      setTimeout(() => {
        setMessage(""); // hide message
      }, 3000);
    }
  };

  React.useImperativeHandle(ref, () => ({
    setSuccess: (mess: string) => handleShowMessage(mess, true),
    setError: (mess: string) => handleShowMessage(mess),
  }));

  return (
    <div
      className={classnames(
        "pointMessage",
        { visible: Boolean(message), success: isSuccess },
        className
      )}
    >
      {message ? (
        <div dangerouslySetInnerHTML={{ __html: message }} />
      ) : (
        <div>waiting..</div>
      )}
      <audio ref={audioRef} />
    </div>
  );
}

export default forwardRef(PointMessage);
