import React, { useState } from 'react'
import { useRecoilState } from 'recoil'
import imagesAssets from '../../../../assets/imgs'
import MainButton from '../../../../components/Button/MainButton'
import UsePointModal from '../../../../components/Modal/UsePointModal'
import detailVideoAtom from '../../../../recoil/detailVideoAtom'
import userInfoAtom from '../../../../recoil/userInfoAtom'
import { formatNumber } from '../../../../utils/format'

type Props = {
  exchangePoints: boolean
}

const BoxUsedPointBase: React.FC<Props> = ({ exchangePoints }) => {
  const [userInfo] = useRecoilState(userInfoAtom);
  const [detailVideo] = useRecoilState(detailVideoAtom);
  const [showUsePoint, setShowUsePoint] = useState(false)

  const { products = [] } = detailVideo ?? {};

  const bannerProduct = imagesAssets.banner
  const photoProduct = products[0]?.product_banner ?? imagesAssets.promotion

  const handleOpenUsePointModal = () => {
    setShowUsePoint(true)
  }

  const handleClose = () => {
    setShowUsePoint(false)
  }

  const renderBoxExchangePoint = () => {
    return <div className="banner exchangePoints">
      <div className='md:flex md:items-center md:justify-center'>
        <div className='exchangeImageWrapper'>
          <img className="exchangeImage min-h-[120px] md:h-[120px]" src={photoProduct} alt="" />
        </div>

      </div>
      <div className='timeBox'>
        <div className='countdownTime'>
          <div>Bạn có <span className='points'>{formatNumber(userInfo?.amount)}</span> VNĐ</div>
        </div>
        <MainButton onClick={handleOpenUsePointModal} className='pointBtn'>
          SỬ DỤNG NGAY
        </MainButton>
      </div>
    </div>
  }

  const renderImageFocus = () => {
    return <div className="banner">
      <img className="bannerImage constant-tilt-shake" src={bannerProduct} alt="" />
    </div>
  }

  return (
    <>
      {exchangePoints ? renderBoxExchangePoint() : renderImageFocus()}
      <UsePointModal open={showUsePoint} onCancel={handleClose} />
    </>
  )
}

const BoxUsedPoint = React.memo(BoxUsedPointBase)

export default BoxUsedPoint
