import { atom, selector } from "recoil";
import { ResultRecordRequestType, SumPointType } from "../types";
import { formatPoint } from "../utils";
import pointRuleSelector from "./pointRuleSelector";

const resultRecordAtom = atom<ResultRecordRequestType>({
  key: "resultRecordAtom",
  default: {
    result: [],
    quizzes: [],
  },
});

export const sumPointSelector = selector({
  key: "sumPointSelector",
  get: ({ get }): SumPointType => {
    const { quizzes, result } = get(resultRecordAtom);
    const {
      addQuizNumber,
      subtractQuizNumber,
      addResultNumber,
      subtractResultNumber,
    } = get(pointRuleSelector);

    let resultPoint = 0;
    result.forEach((item) => {
      if (item.result) resultPoint += addResultNumber;
      else resultPoint += subtractResultNumber;
    });
    resultPoint = resultPoint < 0 ? 0 : formatPoint(resultPoint);

    // calc sum of quizzes
    let quizzesPoint = 0;
    quizzes.forEach((item) => {
      if (item.result) quizzesPoint += addQuizNumber;
      else quizzesPoint += subtractQuizNumber; // negative number
    });
    quizzesPoint = quizzesPoint < 0 ? 0 : formatPoint(quizzesPoint);

    return {
      resultPoint,
      quizzesPoint,
      sumPoint: formatPoint(resultPoint + quizzesPoint),
    };
  },
});

export default resultRecordAtom;
