import UserSVG from "./UserSVG"
import Search from "./Search"
import Gift from "./Gift"
import Exit from "./Exit"
import Close from "./Close"
import Minus from "./Minus"
import Plus from "./Plus"
import Done from "./Done"
import Menu from "./Menu"
import Back from "./Back"
import Home from "./Home"
import Coupon from "./Coupon"
import Next from "./Next"
import Cart from "./Cart"
import VideoPlay from "./VideoPlay"
import History from "./History"
import Logout from "./Logout"
import Withdraw from "./Withdraw"
import Copy from "./Copy"
import Play from "./Play"

const Svg = {
  UserSVG,
  Search,
  Gift,
  Exit,
  Close,
  Minus,
  Done,
  Plus,
  Menu,
  Back,
  Home,
  Coupon,
  Next,
  Cart,
  VideoPlay,
  History,
  Logout,
  Withdraw,
  Copy,
  Play
}

export default Svg