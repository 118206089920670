import React, { useEffect } from 'react'
import { Col } from 'antd'
import imagesAssets from '../../assets/imgs'
import { CategoryItem } from '../../components/Item'
import { mobileAndTabletCheck } from '../../utils'
import { apis } from '../../apis'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import customerBrandsAtom from '../../recoil/customerBrandAtom'
import { CustomerBranch } from '../../types'
import videosAtom from '../../recoil/videosAtom'
import { brandTotal, brandMyVideo } from './constant'
import userInfoAtom from '../../recoil/userInfoAtom'
import { useHistory } from 'react-router-dom'
import pathUrl from '../../routes/path'

const Slide = () => {
  const history = useHistory()

  const setCustomerBrands = useSetRecoilState(customerBrandsAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const customerBrandsStore = useRecoilValue(customerBrandsAtom);
  const [videosStore, setVideosStore] = useRecoilState(videosAtom)
  const { customerId } = videosStore?.filter ?? {}

  const handleSelectBrand = (item: CustomerBranch) => {
    setVideosStore(value => ({
      ...value, filter: {
        ...value?.filter,
        customerId: item?._id
      }
    }))
  }

  const handleClickHome = () => {
    history.push(pathUrl.home())
    handleSelectBrand(brandTotal)
  }

  const renderOtherLineITem = () => {
    const newBrandTotal = { ...brandTotal }
    newBrandTotal.onClick = handleClickHome

    const brands = [newBrandTotal]

    if (userInfo?.email) {
      brands.push(brandMyVideo)
    }

    return (
      <>
        {brands.map((item) => <CategoryItem
          imageActive={item.imgActive}
          isActive={customerId === item._id}
          onClick={() => item.onClick ? item.onClick() : handleSelectBrand(item)}
          image={item.img} name={item.name} />)}
        <hr />
        <CategoryItem imageActive={imagesAssets?.help} isActive={false} onClick={() => null} image={imagesAssets.help} name='Trợ giúp' />
        <hr />
        <CategoryItem imageActive={imagesAssets?.help} isActive={false} onClick={() => null} image={imagesAssets.feedback} name='Phản hồi' />
      </>
    )
  }


  const renderLine = (item: CustomerBranch) => {
    return <CategoryItem imageActive={item?.imgActive} key={item._id} isActive={customerId === item?._id} onClick={() => handleSelectBrand(item)} image={imagesAssets.item} name={item.name} />
  }

  const renderCustomerBrands = () => {
    const categoriesMenu = customerBrandsStore?.customerBrands;

    return categoriesMenu ? categoriesMenu?.map((item) => renderLine(item)) : <></>
  }

  React.useEffect(() => {
    const getApi = () => {
      setCustomerBrands({ loading: true, customerBrands: [] })

      apis.getCustomerBrands().then((result) => {
        if (result) {
          setCustomerBrands({ loading: false, customerBrands: result.customers })
        }
      })
    }

    getApi()
  }, [])

  useEffect(() => {
    if (!userInfo?.email) {
      handleSelectBrand(brandTotal)
    }
  }, [userInfo])


  return (
    <Col span={mobileAndTabletCheck ? 0 : 4} className="">
      <div className=' fixed z-10 h-screen top-0 w-[16.7%]'>
        <div className='mt-[62px]' />
        <div className='bg-[#FFFFFF] h-full overflow-auto'>
          <div className="">
            {renderOtherLineITem()}
            {/* <div className="pl-5 py-4">
          NHÃN HÀNG
        </div>
        <div className='min-h-[15rem]'>
          {renderCustomerBrands()}
        </div> */}
          </div>
          <div className="pb-5 pt-5 border-t border-t-[#E5E5E5] px-5">
            <a href="#"><p className="py-1 text-base font-semibold">Giới thiệu</p></a>
            <a><p className="py-1 text-base font-semibold">Deepview trên báo</p></a>
            <a><p className="py-1 text-base font-semibold">Đội ngũ</p></a>
            <a><p className="py-1 text-base font-semibold">Tuyển dụng</p></a>
            <a><p className="py-1 text-base font-semibold">Cửa hàng</p></a>

            <p className="py-1 text-sm mt-5 font-medium">Điện thoại: 0779611553</p>
            <p className="py-1 text-sm font-medium">Email: admin@deepview.win</p>
            <p className="py-1 text-sm font-medium">Chat: m.me/ilovedeepview</p>
            <p className="py-1 mt-5 text-sm font-medium">© 2022 Deepview</p>
          </div>
        </div>
      </div>
    </Col>
  )
}

export default Slide