import { selector } from "recoil";
import { PointRuleType, VideoType } from "../types";
import detailVideoAtom from "./detailVideoAtom";

const pointRuleSelector = selector({
  key: "pointRuleSelector",
  get: ({ get }): PointRuleType => {
    const detailVideo: VideoType = get(detailVideoAtom) as VideoType;
    const { point, questions = [], quizzes = [], activeQuestionIndex } = detailVideo ?? {};

    const questionPoint = point / questions.length;
    const unitNumber: number =
      quizzes.length * 2 +
      questions?.[activeQuestionIndex || 0]?.answers?.length;

    return {
      addResultNumber: questionPoint / unitNumber,
      subtractResultNumber: -(questionPoint / unitNumber / 4),
      addQuizNumber: (questionPoint / unitNumber) * 2,
      subtractQuizNumber: -(((questionPoint / unitNumber) * 2) / 4),
    };
  },
});

export default pointRuleSelector;
