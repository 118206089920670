import { atom } from "recoil";
import { UserType } from "./../types/index.d";
import { recoilPersist } from "recoil-persist";
const { persistAtom } = recoilPersist();

const userInfoAtom = atom<UserType>({
  key: "userInfoAtom",
  default: {},
  effects_UNSTABLE: [persistAtom],
});

export default userInfoAtom;
