import React from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import { apis, getVideoList } from "../../apis";
import videoListAtom from "../../recoil/videoListAtom";
import { VideoType } from "../../types";

const useApp = () => {
  const [videoList, setVideoList] = useRecoilState(videoListAtom);
  const { videoCategory } = useParams<any>();

  React.useEffect(() => {
    if (videoList.category === videoCategory) return;
    (async () => {
      try {
        setVideoList((videoList) => ({
          ...videoList,
          category: videoCategory,
          loading: true,
        }));
        const result = await apis.getVideoList({ category: videoCategory, page_limit: 1000 });
        setVideoList((videoList) => ({
          ...videoList,
          videoMap: result.videos.reduce(
            (videoMap: Record<string, VideoType>, video: VideoType) => {
              videoMap[video.id!] = video;
              return videoMap;
            },
            {}
          ),
          total: result.videos.length,
          loading: false,
        }));
      } catch (e) {
        setVideoList((videoList) => ({ ...videoList, loading: false }));
      }
    })();
  }, [videoCategory]);
};

export default useApp;
