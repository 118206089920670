import { atom } from "recoil";
import { AppModalType } from "../types";
import { ModalType } from "../types/enum";

const appModalAtom = atom<AppModalType>({
  key: "appModalAtom",
  default: {
    mode: ModalType.close
  },
});

export default appModalAtom;
