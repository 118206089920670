import React from 'react'
import { SvgIconProps } from './type'

const History: React.FC<SvgIconProps> = ({ width = 24, height = 23, color = '#030303' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M24 11.8427C23.9414 12.2431 23.9032 12.6479 23.821 13.0436C22.9548 17.2108 20.525 20.0508 16.5063 21.4446C12.0106 23.0037 6.8678 21.2496 4.18409 17.3265C3.85073 16.8392 3.9403 16.2441 4.39674 15.913C4.85642 15.5796 5.4665 15.6868 5.81673 16.1777C7.11039 17.991 8.82873 19.1861 10.9889 19.7361C15.8877 20.9834 20.9732 17.6988 21.8452 12.7275C22.6555 8.1085 19.9704 3.77414 15.4708 2.51117C10.6649 1.16219 5.75428 4.0378 4.48937 8.78296C4.47775 8.8264 4.47093 8.87122 4.45537 8.94717C4.97987 8.59431 5.47443 8.25395 5.97706 7.92591C6.36355 7.67369 6.8331 7.70914 7.1739 7.99773C7.50281 8.27627 7.62939 8.75745 7.43868 9.14519C7.35617 9.31302 7.22403 9.48067 7.07138 9.58581C5.98481 10.334 4.89011 11.0704 3.79179 11.8012C3.25916 12.1558 2.67871 12.0203 2.31266 11.4667C1.53919 10.2969 0.770596 9.12381 0 7.95204C0 7.74889 0 7.54574 0 7.34265C0.35717 6.41985 1.486 6.40066 1.92999 7.24914C2.10183 7.57756 2.33629 7.87316 2.56113 8.21214C2.6027 8.07699 2.6342 7.98136 2.66152 7.88453C4.1659 2.55262 9.62189 -0.839871 15.0511 0.181194C19.4179 1.00248 22.8617 4.37453 23.7649 8.72589C23.8691 9.22814 23.9228 9.74095 24 10.2488C24 10.7801 24 11.3114 24 11.8427Z" fill={color} />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 8.44366C12 7.6393 11.9988 6.83495 12.0004 6.0306C12.0017 5.40359 12.4124 4.95622 12.9873 4.95109C13.5759 4.94597 14.001 5.39059 14.0026 6.02917C14.0064 7.47385 14.0063 8.91859 14.0006 10.3631C14 10.5239 14.0451 10.6378 14.1595 10.7521C15.01 11.6023 15.8554 12.4576 16.6989 13.3148C16.9863 13.6068 17.0776 13.9579 16.9465 14.3489C16.8216 14.7219 16.549 14.9398 16.165 15.0086C15.8352 15.0676 15.5386 14.9736 15.305 14.7395C14.2899 13.7226 13.28 12.7005 12.2721 11.6766C12.0773 11.4785 11.999 11.2279 11.9995 10.9503C12.001 10.1147 12.0001 9.2792 12 8.44366Z" fill="black" />
    </svg>
  )
}

export default History