import * as React from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import "./styles.scss";

import deepcareLogoImg from "../../assets/imgs/deepCareLogo.png";
import userInfoAtom from "../../recoil/userInfoAtom";
import SearchField from "../Field/SearchField";
import StrokeBlue from "../Button/StrokeBlueBtn";
import appModalAtom from "../../recoil/appModalAtom";
import { ModalType } from "../../types/enum";
import UserInfo from "./UserInfo";
import pathUrl from "../../routes/path";
import Svg from "../Svg";
import { mobileAndTabletCheck } from "../../utils";
import MenuMobile from "./MenuMobile";
import videosAtom from "../../recoil/videosAtom";

function Header() {
  const history = useHistory()

  const [userInfo] = useRecoilState(userInfoAtom);
  const setAppModal = useSetRecoilState(appModalAtom);
  const [videosStore, setVideosStore] = useRecoilState(videosAtom)
  const [showMenu, setShowMenu] = React.useState(false)

  const handleClickLogin = () => {
    setAppModal({ mode: ModalType.signIn })
  }

  const handleClickLogo = () => {
    history.push(pathUrl.home())
  }

  const showInputSearchMobile = () => {
    setAppModal({ mode: ModalType.search })
  }

  const handleClickOpenMenu = () => {
    setShowMenu(true)
  }

  const handleCloseMenu = () => {
    setShowMenu(false)
  }

  const handleSearch = (searchInput: string) => {
    setVideosStore(value => ({
      ...value,
      filter: {
        ...value?.filter,
        search: searchInput
      }
    }))

    history.push(pathUrl.home())
  }
  return (
    <div className="header fixed top-0 w-full z-20 h-[62px] content-center md:justify-end md:flex-row md:flex-nowrap">
      <div className="logo md:flex-1" onClick={handleClickLogo}><img className="click cursor-pointer" src={deepcareLogoImg} alt="logo" /></div>
      <div className="search flex md:hidden">
        <SearchField onSearch={handleSearch} />
      </div>
      <div className="hidden md:block">
        <Button className="border-none" onClick={showInputSearchMobile}><Svg.Search /></Button>
      </div>
      <div className="btn-login md:m-0">
        {
          userInfo && userInfo.email
            ? <UserInfo />
            : <StrokeBlue className="!rounded-[100px] bg-[#F8F8F8]" icon={mobileAndTabletCheck ? undefined : <Svg.UserSVG />} onClick={handleClickLogin} title="TÀI KHOẢN" />
        }
      </div>
      <div className="hidden md:block" onClick={handleClickOpenMenu}>
        <Button className="border-none"><Svg.Menu /></Button>
      </div>
      <MenuMobile open={showMenu} onCancel={handleCloseMenu} />
    </div>
  );
}

export default Header;
