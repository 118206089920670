import React from 'react'

const Exit = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_325_672)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 2.62616C0.0622798 2.40319 0.110088 2.17496 0.189124 1.95807C0.611725 0.799065 1.69169 0.0130816 2.92938 0.00787718C5.92848 -0.00469528 8.92769 0.000684565 11.9268 0.00401773C12.5218 0.00466097 12.9483 0.428265 12.9491 0.992505C12.95 1.55903 12.5351 1.97678 11.9296 1.98158C10.6801 1.99146 9.43039 1.9852 8.18075 1.98526C6.50153 1.98538 4.82232 1.98672 3.14311 1.98427C2.73715 1.98368 2.36025 2.07654 2.16491 2.45454C2.05295 2.67119 2.00268 2.94299 2.00209 3.19011C1.99237 7.02541 1.9953 10.8608 1.9953 14.6961C1.9953 16.7073 1.997 18.7185 1.99372 20.7297C1.99307 21.0791 2.05992 21.3937 2.33652 21.6363C2.57626 21.8465 2.86323 21.8882 3.1666 21.8882C6.02521 21.8872 8.88381 21.8872 11.7424 21.8886C11.8903 21.8887 12.0421 21.885 12.1855 21.9146C12.6671 22.0138 12.9822 22.4628 12.9435 22.9738C12.9079 23.4453 12.5212 23.8291 12.0439 23.8654C11.9817 23.8702 11.9191 23.8708 11.8566 23.8708C8.91996 23.871 5.98326 23.8747 3.04656 23.8693C1.57334 23.8666 0.410766 22.9453 0.0743491 21.5354C0.0512652 21.4388 0.0249588 21.3429 0.000117177 21.2467C0 15.0398 0 8.83298 0 2.62616Z" fill="black" />
        <path fillRule="evenodd" clipRule="evenodd" d="M24.0001 12.1234C23.828 12.3684 23.6876 12.6456 23.4785 12.8536C21.586 14.7356 19.6732 16.5972 17.7852 18.4835C17.1844 19.0839 16.5644 18.9349 16.2347 18.5977C15.895 18.2503 15.7771 17.6323 16.381 17.0516C17.7197 15.7642 19.0316 14.4491 20.3579 13.1488C20.4159 13.0921 20.5089 13.0708 20.5856 13.0331C20.5583 12.9976 20.5311 12.9621 20.5039 12.9266C20.4135 12.9266 20.323 12.9266 20.2326 12.9266C16.5222 12.9266 12.8118 12.927 9.10146 12.9262C8.49477 12.9261 8.10685 12.6435 7.99641 12.1275C7.87502 11.5605 8.29932 10.9944 8.87893 10.9529C8.98779 10.9452 9.09747 10.9462 9.20686 10.9462C12.8782 10.9459 16.5495 10.9459 20.2209 10.9459C20.3131 10.9459 20.4052 10.9459 20.5619 10.9459C20.4661 10.8442 20.4086 10.779 20.3469 10.7182C19.0129 9.40349 17.6798 8.08794 16.3432 6.7759C16.0523 6.49042 15.8819 6.17049 15.9767 5.75128C16.1366 5.04383 16.9673 4.74203 17.5453 5.18341C17.6256 5.24469 17.6996 5.3151 17.7717 5.38615C19.7065 7.29184 21.6431 9.1959 23.5713 11.1083C23.7424 11.278 23.8584 11.503 24 11.7024C24.0001 11.8427 24.0001 11.9831 24.0001 12.1234Z" fill="black" />
      </g>
      <defs>
        <clipPath id="clip0_325_672">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Exit