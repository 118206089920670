import React, { useCallback } from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import imagesAssets from "../../assets/imgs";

import "./styles.scss";

interface Props<P = any> {
  data: P[]
  renderItem: (item: P, index: number) => JSX.Element
  className?: string;
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  const handlePrev = () => {
    scrollPrev()
  }

  return (
    <div
      style={{ visibility: isFirstItemVisible ? "hidden" : "unset" }}
      onClick={handlePrev}
      className="scrollIcon iconLeft"
    >
      <img src={imagesAssets.previousIcon} alt="" />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  const handleNext = () => {
    scrollNext()
  }

  return (
    <div
      style={{ visibility: isLastItemVisible ? "hidden" : "unset" }}
      onClick={handleNext}
      className="scrollIcon iconRight"
    >
      <img src={imagesAssets.nextIcon} alt="" />
    </div>
  );
}

const HorizontalScrolling: React.FC<Props> = (props) => {
  const { data, renderItem, className } = props;

  const renderList = () => {
    return data && data.map((item, index) => renderItem(item, index))
  }

  return (
    <ScrollMenu
      LeftArrow={LeftArrow}
      RightArrow={RightArrow}
      wrapperClassName={`hozScroll ${className ?? ''}`}
    >
      {renderList()}
    </ScrollMenu>
  );
}

export default HorizontalScrolling;
