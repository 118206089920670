import * as React from "react";
import { Form, Input, Row, Col, InputNumber, Button, message } from "antd";
import { useRecoilState, useSetRecoilState } from "recoil";
import _ from 'lodash'

import BaseModal, { BaseModalProps } from "../BaseModal";
import "./styles.scss";
import PrimaryBtn from "../../Button/PrimaryBtn";
import userInfoAtom from "../../../recoil/userInfoAtom";
import Svg from "../../Svg";
import Products from "./Products";
import appModalAtom from "../../../recoil/appModalAtom";
import { ModalType } from "../../../types/enum";
import { mobileAndTabletCheck } from "../../../utils";
import detailVideoAtom from "../../../recoil/detailVideoAtom";
import { Product } from "../../../types";
import { apis, CouponType, IOrderPayload, IProductPayload } from "../../../apis";
import { formatCurrency } from "../../../utils/format";
import Voucher from "./Voucher";
import useInfoProfile from "../../useHook/useInfoProfile";

interface Props extends BaseModalProps {
  onCancel: () => void;
}

interface IProductSelectedObject {
  quantity: number
  product: Product
}

const calculateProduct = (productOrders: IProductSelectedObject[] = [], isDiscount = false) => {
  let totalPrice = 0
  let totalSalePrice = 0
  let totalPromotion = 0

  productOrders.forEach(({ product, quantity }) => {
    totalPrice += product?.price * quantity
    totalSalePrice += product?.sale_price * quantity
    if (isDiscount) {
      totalPromotion += product?.promotion_discount * quantity
    }
  })

  return {
    totalPrice,
    totalSalePrice,
    totalPromotion,
  }
}

const convertObjectToArrProduct = (products: Product[], productsObj: Record<string, number | undefined>) => {
  const productsSelected: IProductSelectedObject[] = []

  Object.keys(productsObj).forEach((productId) => {
    const productFound = products.find((item) => item._id === productId)

    if (productsObj[productId] && productFound) {
      productsSelected.push({
        quantity: productsObj[productId] as number,
        product: productFound
      })
    }
  })

  return productsSelected
}

const generatePayloadProducts = (productsSelected: IProductSelectedObject[]): IProductPayload[] => {
  return productsSelected.map(({ product, quantity }) => ({
    amount: quantity,
    product_id: product?._id,
  }))
}

const UsePointModal: React.FC<Props> = (props) => {
  const { handleRefreshProfile } = useInfoProfile()
  const [form] = Form.useForm();

  const [detailVideo, setDetailVideo] = useRecoilState(detailVideoAtom);
  const setAppModal = useSetRecoilState(appModalAtom);
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [loading, setLoading] = React.useState(false);
  const [showVoucher, setShowVoucher] = React.useState(false)
  const [isDiscount, setIsDiscount] = React.useState(false)

  const products = detailVideo?.products ?? []

  const handleClose = () => {
    props?.onCancel();
  }

  const handleShowVoucher = () => {
    setShowVoucher(true)
  }

  const handleHiddenVoucher = () => {
    setShowVoucher(false)
  }

  const handleSubmit = async () => {
    try {
      const fieldsValue = await form.validateFields();
      setLoading(true);


      const productsSelected = convertObjectToArrProduct(products, fieldsValue.products)

      const discount = fieldsValue.money ?? 0

      const dataOrder: IOrderPayload = {
        customer_address: fieldsValue.shippingAddress,
        customer_name: fieldsValue.name,
        customer_phone: fieldsValue.mobile,
        products: generatePayloadProducts(productsSelected),
        promotion_code: fieldsValue?.coupon,
        cash_discount: discount,
      }

      await apis.createOrder(dataOrder)

      handleClose();
      setAppModal({ mode: ModalType.thanks })
      handleRefreshProfile()
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  const handleSelectProduct = (item: Product) => {
    const productSelects = form.getFieldValue('products') ?? {}

    if (productSelects[item?._id]) {
      delete productSelects[item?._id]
    } else {
      productSelects[item?._id] = 1
    }

    form.setFieldValue('products', productSelects)

    const errors = form.getFieldsError()

    let hasError = false
    errors.forEach((item) => {
      if (item?.errors?.length > 0) {
        hasError = true
      }
    })

    if (hasError) {
      form.validateFields()
    }
  }

  const productsValue = Form.useWatch('products', form);

  const handleChangeQuantity = (productId: string, value: number) => {
    try {
      const productSelects = form.getFieldValue('products') ?? {}

      productSelects[productId] += value

      form.setFieldValue('products', productSelects)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleClickMax = () => {
    try {
      form.setFieldValue('money', userInfo?.amount)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleMoneyBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
    try {
      const value = +event.currentTarget?.value
      form.setFieldValue('money', value >= (userInfo?.amount || 0) ? userInfo?.amount : value)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleCouponBlur: React.FocusEventHandler<HTMLInputElement> = async (event) => {
    try {
      const value = event.currentTarget?.value

      if (value.trim() === '') {
        setIsDiscount(false)
        form.setFields([
          {
            name: 'coupon',
            errors: undefined,
          },
        ]);
        return
      }

      setLoading(true)

      const response = await apis.getCoupons(value)

      if (response.message === "ok") {
        setIsDiscount(true)
        form.setFields([
          {
            name: 'coupon',
            errors: undefined,
          },
        ]);
      } else {
        form.setFields([
          {
            name: 'coupon',
            errors: ['Mã giảm giá không hợp lệ'],
          },
        ]);
        setIsDiscount(false)
      }
    } catch (error) {
      form.setFields([
        {
          name: 'coupon',
          errors: ['Mã giảm giá không hợp lệ'],
        },
      ]);
      setIsDiscount(false)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    try {
      form.setFieldValue('quantity', 1)
    } catch (error) {
      console.log(error)
    }
  }, [props?.open])

  React.useEffect(() => {
    if (!props?.open) {
      form.resetFields()
    }

  }, [props?.open])

  const renderSummary = () => {
    const productSelects = form.getFieldValue('products') ?? {}

    const productsSelected = convertObjectToArrProduct(products, productSelects)

    if (productsSelected.length === 0) {
      return <></>
    }

    const { totalPrice, totalSalePrice, totalPromotion } = calculateProduct(productsSelected, isDiscount)

    const promotionMoney = form.getFieldValue('money') ?? 0
    const promotion = totalPromotion
    const total = totalSalePrice - promotion - promotionMoney

    return <Row className="py-4 px-7 bg-[#E2EFFC] rounded-[10px]" gutter={{ lg: 15, xs: 4 }}>
      <div className="flex justify-between w-full">
        <span>Giá</span>
        <span>
          <span className="text-[#606060] line-through mr-3">{formatCurrency(totalPrice)}</span>
          <span className="text-[#606060] ">{formatCurrency((totalSalePrice))}</span>
        </span>
      </div>
      <div className="flex justify-between w-full">
        <span>Tổng giảm giá</span>
        <span className="text-[#D32F2F]">- {formatCurrency(promotion)}</span>
      </div>
      <div className="flex justify-between w-full">
        <span>Số tiền sử dụng</span>
        <span className="text-[#D32F2F]">- {formatCurrency(promotionMoney)}</span>
      </div>
      <div className="flex justify-between w-full">
        <span>Tổng cộng</span>
        <span className="text-[#1F91FA]">{formatCurrency(total)}</span>
      </div>
    </Row>
  }

  const renderListQuantity = () => {
    const productSelects = form.getFieldValue('products') ?? {}

    const productsSelected = convertObjectToArrProduct(products, productSelects)

    if (_.isEmpty(productsSelected)) {
      return <></>
    }

    return (
      <>
        {productsSelected.map(({ product, quantity }) => {
          const totalPromotion = product?.promotion_discount

          return (
            <div className="flex justify-between w-full mb-[15px]">
              <div>
                <div className="text-[#030303]">{product?.name}</div>
                {isDiscount
                  ? (
                    <div className="text-[#606060]">
                      <span className="line-through">{formatCurrency(product?.sale_price)}</span>
                      <span className="mx-2">|</span>
                      <span className="">Mã giảm giá: -{formatCurrency(totalPromotion)}</span>
                      <span className="mx-2">|</span>
                      <span className="">{formatCurrency(product?.sale_price - totalPromotion)}</span>
                    </div>
                  )
                  : <></>}

              </div>
              <div className="w-fit max-w-[150px]">
                <InputNumber
                  addonBefore={<Button disabled={quantity === 1} onClick={() => handleChangeQuantity(product?._id, -1)} className="quantityBtn"><Svg.Minus width={10} /></Button>}
                  addonAfter={<Button onClick={() => handleChangeQuantity(product?._id, 1)} className="quantityBtn"><Svg.Plus width={10} /></Button>}
                  className="quantity"
                  value={quantity}
                />
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const renderVoucher = () => {
    return <Voucher onBack={handleHiddenVoucher} />
  }

  const isDisableSubmit = () => {
    const isTouchedForm = form.isFieldsTouched(['name', 'mobile', 'shippingAddress'], true)

    const hasError = form.getFieldsError().filter((item) => item.errors.length > 0).length > 0

    const productsValue = form.getFieldValue('products')

    return !isTouchedForm || hasError || _.isEmpty(productsValue)
  }

  return (
    <BaseModal
      className="pointModal"
      width='60%'
      {...props}
    >
      {
        showVoucher
          ? renderVoucher()
          : <Form className='order' layout="vertical" form={form}>
            <div className="title">Vui lòng cung cấp thông tin</div>
            <div className="subTitle">Chúng tôi sẽ liên hệ với bạn trong 24 giờ. </div>

            <Row gutter={{ lg: 15 }}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Họ tên"
                  name="name"
                  rules={[{ required: true, message: "Thông tin bắt buộc" }]}
                >
                  <Input allowClear placeholder="Họ tên" />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Số điện thoại"
                  name="mobile"
                  rules={[
                    { required: true, message: "Thông tin bắt buộc" },
                  ]}
                >
                  <Input type="number" allowClear className="mobile" placeholder="Số điện thoại" maxLength={11} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              label="Địa chỉ nhận hàng"
              name="shippingAddress"
              rules={[{ required: true, message: "Thông tin bắt buộc" }]}
            >
              <Input.TextArea allowClear rows={mobileAndTabletCheck ? 2 : 1} placeholder="Địa chỉ nhận hàng" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label="Chọn một sản phẩm"
              name="products"
              rules={[{ required: true, message: "Thông tin bắt buộc" }]}
            >
              <Products products={products} onselect={handleSelectProduct} productsSelected={productsValue} />
            </Form.Item>

            <Row gutter={{ lg: 15 }}>
              <Col xs={24} lg={12}>
                <Form.Item
                  label="Mã giảm giá"
                  name="coupon"
                >
                  <Input allowClear placeholder="Mã giảm giá" onBlur={handleCouponBlur} />
                </Form.Item>
              </Col>
              <Col xs={24} lg={12}>
                <Form.Item
                  label={
                    <div className="flex w-full justify-between">
                      <div>Số tiền sử dụng</div>
                      <div onClick={handleClickMax} className="cursor-pointer text-[#1F91FA]">Tối đa</div>
                    </div>
                  }
                  name="money"
                  className="item-full"
                >
                  <Input type="number" allowClear onBlur={handleMoneyBlur} className="mobile" placeholder="Số tiền sử dụng" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item shouldUpdate className="m-0">
              {() => {
                return <>
                  {renderListQuantity()}
                  {renderSummary()}
                </>
              }}
            </Form.Item>

            <div className="submit-footer md:!justify-center flex justify-end gap-2">
              <PrimaryBtn
                className="cancelBtn md:!rounded-[100px]"
                onClick={handleClose}
              >
                Hủy
              </PrimaryBtn>

              <Form.Item shouldUpdate>
                {() => (
                  <PrimaryBtn disabled={isDisableSubmit()} className="md:!rounded-[100px]" onClick={handleSubmit} loading={loading}>
                    Đặt hàng
                  </PrimaryBtn>
                )}
              </Form.Item>
            </div>
          </Form>
      }

    </BaseModal>
  );
}

export default UsePointModal;
