import "./styles.scss";
import bctImg from '../../assets/imgs/bct.png';

function Footer() {
  return (
    <div className="footer">
      Copyright © 2022 Deepview
    </div>
  );
}

export default Footer;
