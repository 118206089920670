import request from "../utils/request";
import _ from 'lodash'
import { ApiResultRecordRequestType, CustomerBranch, UserType, VideoDetailType, VideoType } from "../types";
import { CategoryType } from "../recoil/categoriesAtom";
import { getRecoil } from "recoil-nexus";
import userInfoAtom from "../recoil/userInfoAtom";

export const postSignIn = async (data: { password: string }) =>
  request.post("/users/sign_in", data);

export const postSignUp = async (data: {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  userType: string;
  company?: string;
  companyAddress?: string;
  refCode?: string;
}) => request.post("/users/sign_up", data);

export const getVideoList = async ({ category }: { category?: string }) =>
  request.get(category ? `/videos/${category}` : "/videos");

export const postResultRecord = (data: ApiResultRecordRequestType) =>
  request.postWithAuth("/record", data);

export const getQuestionStatus = async ({
  videoId,
  questionId,
}: {
  videoId: string;
  questionId: number;
}) => request.getWithAuth(`/videos/${videoId}/questions/${questionId}`);

interface ICategory {
  customer_id?: string
}

export const getCategories = (search?: ICategory): Promise<{ categories: CategoryType[] }> => {
  const searchParams = _.omitBy(search, _.isNil);
  return request.get("/categories?", searchParams);
}

export interface IVideoParams {
  page?: number
  page_limit?: number
  customer_id?: string
  purchased?: boolean
  category?: string
  q?: string
}

export interface IVideoResponse {
  videos: VideoType[]
  page: number
  limit: number
}

export enum IMessageVideo {
  ENOUGH_BALANCE = 'ENOUGH_BALANCE',
  ERROR_NOT_ENOUGH_BALANCE  = 'ERROR_NOT_ENOUGH_BALANCE'
}

export interface IVideoDetailResponse {
  video: VideoType
  message?: IMessageVideo
}

export interface IProductPayload {
  product_id: string,
  amount: number
}
export interface IOrderPayload {
  customer_name: string,
  customer_phone: string,
  customer_address: string,
  products: IProductPayload[],
  promotion_code: string,
  cash_discount: string,
}

export type IOrderResponse = IOrderPayload

export interface CouponType {
  _id: string;
  code: string;
  description: string;
  discount_amount: number;
  exchange_price: number;
  quantity: number;
  status: string;
  valid_for: number;
}

export interface MyCouponType {
  code: string;
  coupon_id: string;
  description: string;
  discount_amount: number;
  exchange_price: number;
  exchanged_at: string;
  expired_at: string;
  user_id: string;
  user_name: string;
}

export interface ICouponsResponse {
  coupons: CouponType[]
}

export interface IMyCouponsResponse {
  coupons: MyCouponType[]
}

export interface ICheckCouponResponse {
  message: 'ok' | 'nok'
}

export interface ProductResponse {
  amount: number;
  price: number;
  product_id: string;
  product_name: string;
  name: string;
  promotion_discount: number;
  sale_price: number;
  sub_total: number;
}

export interface OrderItem {
  _id: string;
  cash_discount: number;
  created_at: string;
  created_by: string;
  customer_address: string;
  customer_name: string;
  customer_phone: number;
  products: ProductResponse[];
  promotion_code: string;
  status: string;
  total: number;
  total_original_price: number;
  total_original_sale_price: number;
  total_promotion_discount: number;
  updated_at: string;
}

export interface IOrdersResponse {
  orders: OrderItem[]
}

export interface MyVideo {
  _id: string;
  action: string;
  created_at: string;
  data: {
    video: {
      _id: string
      name: string
    }
  };
  owner_id: string;
  rewarded_points: number;
  updated_at: string;
}

export enum EPurchase {
  PURCHASED = 'PURCHASED',
  SUCCESS = 'SUCCESS',
  ERROR_NOT_ENOUGH_BALANCE = 'ERROR_NOT_ENOUGH_BALANCE',
  ERROR_VIDEO_NOT_FOUND = 'ERROR_VIDEO_NOT_FOUND'
}

export enum EPurchaseStatus {
  PURCHASED = 'PURCHASED',
  NOTYET_PURCHASED = 'NOTYET_PURCHASED',
}

export interface IPurchaseResponse {
  message: EPurchase
}

export interface IPurchaseStatusResponse {
  message: EPurchaseStatus
}

export interface IMyVideoResponse {
  videos: MyVideo[]
}

export interface IGetMeResponse {
  user: UserType
}

export const apis = {
  getCustomerBrands: (): Promise<{ customers: CustomerBranch[] }> => request.get("/customers"),
  getVideoList: async (params: IVideoParams = {}, isAuth = false): Promise<IVideoResponse> => {
    const { category, ...otherParams } = params ?? {}

    const url = `/videos${category ? `/${category}` : ''}?`

    return isAuth ? request.getWithAuth(url, otherParams) : request.get(url, otherParams)
  },
  getVideoDetail: async (id: string): Promise<IVideoDetailResponse> => {
    const { email } = getRecoil(userInfoAtom);
    if (email) {
      return request.getWithAuth(`/videos/details/${id}`)
    }

    return request.get(`/videos/details/${id}`)
  },
  createOrder: async (payload: IOrderPayload): Promise<IOrderResponse> => {
    return request.post(`/orders`, payload, true)
  },
  myCoupons: async (): Promise<IMyCouponsResponse> => {
    return request.getWithAuth(`/coupons/me`)
  },
  coupons: async (): Promise<ICouponsResponse> => {
    return request.getWithAuth(`/coupons`)
  },
  exchangeCoupon: async (id: string): Promise<IOrderResponse> => {
    return request.getWithAuth(`/coupons/${id}/exchange`)
  },
  getCoupons: async (code: string): Promise<ICheckCouponResponse> => {
    return request.getWithAuth(`/coupons/${code}`)
  },
  getOrders: async (): Promise<IOrdersResponse> => {
    return request.getWithAuth(`/orders`)
  },
  purchaseStatus: async (videoId: string): Promise<IPurchaseStatusResponse> => {
    return request.getWithAuth(`/videos/${videoId}/purchase_status`)
  },
  purchaseVideo: async (videoId: string): Promise<IPurchaseResponse> => {
    return request.postWithAuth(`/videos/${videoId}/purchase`, {})
  },
  myVideos: async (): Promise<IMyVideoResponse> => {
    return request.getWithAuth(`/my_videos`)
  },
  getMe: async (): Promise<IGetMeResponse> => {
    return request.getWithAuth(`/users/me`)
  },
}
