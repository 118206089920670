import React from 'react'
import { SvgIconProps } from './type'

const Minus: React.FC<SvgIconProps> = ({ height = 2, width = 16 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.0002 0H0.000244141V2H16.0002V0Z" fill="#000000" />
    </svg>
  )
}

export default Minus