import { message } from "antd";
import { BASE_API } from "../constants/constants";
import { getRecoil } from "recoil-nexus";
import userInfoAtom from "../recoil/userInfoAtom";

const post = <T>(url: string, data: T, withAuth = false) => {
  const { api_key, email, code } = getRecoil(userInfoAtom);
  const requestOptions: any = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...(withAuth && email && code && api_key
        ? { "X-User-Email": email, "X-User-Code": code, "X-Api-Key": api_key }
        : {}),
    },
    body: JSON.stringify(data),
  };
  return fetch(`${BASE_API}${url}`, requestOptions)
    .then(async (response) => {
      const isJson = response.headers
        .get("content-type")
        ?.includes("application/json");
      const data = isJson && (await response.json());

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = { code: response.status, message: (data && data.message) || response.status }
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error);
      message.error(error?.message.toString());
      throw error;
    });
};

const get = (url: string, data?: any, withAuth = false) => {
  const { api_key, email, code } = getRecoil(userInfoAtom);
  const requestOptions: any = {
    method: "GET",
    headers: {
      ...(withAuth && email && code && api_key
        ? { "X-User-Email": email, "X-User-Code": code, "X-Api-Key": api_key }
        : {}),
    },
  };
  const urlString = data ? new URLSearchParams(data).toString() : "";
  return fetch(`${BASE_API}${url}${urlString}`, requestOptions)
    .then(async (response) => {
      const data = await response.json();

      // check for error response
      if (!response.ok) {
        // get error message from body or default to response status
        const error = { code: response.status, message: (data && data.message) || response.status }
        return Promise.reject(error);
      }

      return data;
    })
    .catch((error) => {
      console.error("There was an error!", error);
      message.error(error?.message.toString());
      throw error;
    });
};

const request = {
  post,
  get,
  postWithAuth: <T>(url: string, data: T) => post(url, data, true),
  getWithAuth: <T>(url: string, data?: T) => get(url, data, true),
};

export default request;
