import imagesAssets from "../../assets/imgs";
import { CategoryType } from "../../recoil/categoriesAtom";
import { CustomerBranch } from "../../types";

export const categoryTotal: CategoryType = {
  _id: '-1',
  created_at: new Date().toISOString(),
  name: 'Tất cả',
  slug: 'total',
  updated_at: new Date().toISOString(),
}

export const brandTotal: CustomerBranch = {
  _id: '-1',
  name: 'Trang chủ',
  img: imagesAssets.home,
  imgActive: imagesAssets.homeIn
}

export const brandMyVideo: CustomerBranch = {
  _id: '-2',
  name: 'Video của bạn',
  img: imagesAssets.video,
  imgActive: imagesAssets.videoIn,
}