import { Button, Input, InputRef } from "antd";
import * as React from "react";
import Svg from "../../Svg";
import BaseModal, { BaseModalProps } from "../BaseModal";
import "./styles.scss";

interface Props extends BaseModalProps {
  onCancel: () => void;
}

const SearchModal: React.FC<Props> = (props) => {
  const searchRef = React.useRef<InputRef>(null)

  const handleClose = () => {
    props.onCancel && props.onCancel()
  }

  const handleSubmit = () => {
    props.onCancel && props.onCancel()
  }

  React.useEffect(() => {
    if (props.open) {
      setTimeout(() => {
        searchRef?.current?.focus()
      }, 100);
    }
  }, [props.open])



  return (
    <BaseModal
      className="searchModal"
      {...props}
    >
      <div className="searchWrapper">
        <div>
          <Button onClick={handleClose} className="border-none"><Svg.Back /></Button>
        </div>
        <Input
          ref={searchRef}
          className="searchInput"
          autoFocus
          placeholder="Tìm kiếm trên Medtube"
          suffix={<span onClick={handleSubmit}><Svg.Search height={18} width={18} /></span>}
          onSubmit={handleSubmit}
        />
      </div>
    </BaseModal>
  );
}

export default SearchModal;
