import { useCallback, useState } from "react";

const useModal = (initVisible?: boolean) => {
  const [visible, setVisible] = useState(initVisible || false);

  const showModal = useCallback(() => setVisible(true), []);
  const closeModal = useCallback(() => setVisible(false), []);

  return { visible, showModal, closeModal };
};

export default useModal;
