import React from "react";
import { message, Spin } from "antd";
import Video from "./components/Video";
import VideoCollection from "./components/VideoCollection";
import { useRecoilState, useRecoilValue } from "recoil";
import detailVideoAtom from "../../recoil/detailVideoAtom";
import { useHistory, useParams } from "react-router-dom";
import { getRandomInt } from "../../utils";
import "./styles.scss";
import {
  availableVideosSelector,
} from "../../recoil/videoListAtom";
import useApp from "../../components/useHook/useApp";
import Footer from "../../components/Footer";
import { apis, EPurchaseStatus } from "../../apis";
import appModalAtom from "../../recoil/appModalAtom";
import { ModalType } from "../../types/enum";
import { HTTP_STATUS } from "../../constants/http";
import { UserType } from "../../types";
import userInfoAtom from "../../recoil/userInfoAtom";

function VideoQuiz() {
  const history = useHistory()

  useApp();
  let { videoID } = useParams<{ videoID: string }>();
  const [detailVideo, setDetailVideo] = useRecoilState(detailVideoAtom);
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [appModal, setAppModal] = useRecoilState(appModalAtom);
  const { videos } = useRecoilValue(availableVideosSelector);

  const checkVideo = async () => {
    try {
      if (!detailVideo?.id) return

      const response = await apis.purchaseStatus(detailVideo?.id)

      if (response?.message !== EPurchaseStatus.PURCHASED) {
        history.push('/')
      }
    } catch (error: any) {
      if (error?.code === HTTP_STATUS.UNAUTHORIZED) {
        setAppModal({
          mode: ModalType.signIn, data: {
            onCancel: async (userData?: UserType) => {
              if (userData?.email) {
                setAppModal({ mode: ModalType.close })
                await checkVideo()
              }
            }
          }
        })
      } else {
        history.push('/')
      }
    }
  }

  const handleCancel = () => {

  }

  const handleExtraCloseViewMore = () => {
    history.push('/')
    setAppModal({ mode: ModalType.close })
  }



  const handleExtraPurChase = () => {
    getVideo()
    setAppModal({ mode: ModalType.close })
  }

  const getVideo = async () => {
    try {
      const result = await apis.getVideoDetail(videoID)

      if (result.message) {
        setAppModal({
          mode: ModalType.purchaseVideo, data: result?.video, extraPurChase: handleExtraPurChase, onCancel: handleCancel,
          extraCloseViewMore: handleExtraCloseViewMore
        })
      } else {
        setDetailVideo({ ...result?.video, activeQuestionIndex: getRandomInt(result?.video.questions.length) })
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (error: any) {
      if (error?.code === HTTP_STATUS.UNAUTHORIZED) {
        if (!userInfo?.email) {
          setAppModal({
            mode: ModalType.signIn,
            data: {
              onCancel: async (userData?: UserType) => {
                if (userData?.email) {
                  setAppModal({ mode: ModalType.close })
                  await getVideo()
                }
              }
            }
          })
        }
      } else {
        message.error('Error')
        setAppModal({ mode: ModalType.purchaseVideo, onCancel: handleCancel, extraCloseViewMore: handleExtraCloseViewMore })
      }
    }
  }

  React.useEffect(() => {
    getVideo()
  }, [videoID]);

  React.useEffect(() => {
    if (detailVideo && !detailVideo?.is_sponsor && detailVideo?.price > 0) {
      checkVideo()
    }
  }, [detailVideo]);

  React.useEffect(() => {
    return () => {
      setDetailVideo(null)
    }
  }, [])

  if (!detailVideo)
    return (
      <div className="globalLoadingContainer">
        <Spin />
      </div>
    );

  return (
    <>
      <div className="videoQuizContainer md:mt-[62px] md:p-0 md:bg-white">
        <div className="container">
          <Video />
        </div>

        <h2 className="suggestVideoTitle mt-5 md:text-left md:px-3">VIDEO LIÊN QUAN</h2>
        <VideoCollection videos={videos} />
      </div>
      <Footer />
    </>
  );
}

export default VideoQuiz;
