import React from 'react'
import { SvgIconProps } from './type'

const Minus: React.FC<SvgIconProps> = ({ height = 16, width = 16 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7361 8.95414L15.7361 7.1892L8.83246 7.18581L8.83755 0.290672L7.07261 0.290672L7.07261 7.1892L0.174073 7.1892L0.174073 8.95414L7.06837 8.9499L7.07176 15.8535L8.8367 15.8535L8.8367 8.95329L15.7361 8.95414Z" fill="#030303" />
    </svg>
  )
}

export default Minus