import { atom, selector } from "recoil";
import { CustomerBranch } from "../types";

export interface CustomerBrandStore {
  customerBrands: CustomerBranch[]
  loading: boolean
}

const customerBrandsAtom = atom<CustomerBrandStore>({
  key: "customerBrandAtom",
  default: {
    customerBrands: [],
    loading: false
  },
});

export const customerBrandState = selector({
  key: 'customerBrandState',
  get: ({get}) => {
    return get(customerBrandsAtom).customerBrands;
  },
});

export default customerBrandsAtom;
