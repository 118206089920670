import { Collapse, ConfigProvider, Empty, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import * as React from "react";
import Truncate from "react-truncate";
import imagesAssets from "../../../assets/imgs";
import PrimaryBtn from "../../Button/PrimaryBtn";
import BaseModal, { BaseModalProps } from "../BaseModal";
import "./styles.scss";


enum EProductOrder {
  waiting = 'waiting',
  delivering = 'delivering',
  done = 'done',
  cancel = 'cancel',
}

const { Panel } = Collapse

const orderStatusLabel = {
  [EProductOrder.waiting]: 'Chờ duyệt',
  [EProductOrder.delivering]: 'Đang giao',
  [EProductOrder.done]: 'Đã nhận',
  [EProductOrder.cancel]: 'Đã hủy',

}

interface IMyVideo {
  id: string;
  name: string;
  time: string;
  point: number;
}

const dataSource: IMyVideo[] = [
  {
    id: '1',
    name: 'Thực phẩm bảo vệ sức khỏe AstaOmeg1 ®',
    time: new Date().toISOString(),
    point: 1000,
  },
  {
    id: '2',
    name: 'Thực phẩm bảo vệ sức khỏe AstaOmeg1 ®',
    time: new Date().toISOString(),
    point: 1000,
  },
  {
    id: '3',
    name: 'Thực phẩm bảo vệ sức khỏe AstaOmeg1 ®',
    time: new Date().toISOString(),
    point: 1000,
  },
  {
    id: '4',
    name: 'Thực phẩm bảo vệ sức khỏe AstaOmeg1 ®',
    time: new Date().toISOString(),
    point: 1000,
  },
  {
    id: '5',
    name: 'Thực phẩm bảo vệ sức khỏe AstaOmeg1 ®',
    time: new Date().toISOString(),
    point: 1000,
  },
  {
    id: '6',
    name: 'Thực phẩm bảo vệ sức khỏe AstaOmeg1 ®',
    time: new Date().toISOString(),
    point: 1000,
  },
  {
    id: '7',
    name: 'Thực phẩm bảo vệ sức khỏe AstaOmeg1 ®',
    time: new Date().toISOString(),
    point: 1000,
  },
];

const columns: ColumnsType<IMyVideo> = [
  {
    title: 'STT',
    dataIndex: 'stt',
    key: 'stt',
    align: 'center',
    width: 60,
    render: (_text, _record, index) => {
      return index + 1
    },
  },
  {
    title: 'Video',
    dataIndex: 'name',
    key: 'name',
    width: '50%',
    render: (text) => {
      return <div><Truncate lines={3}>{text}</Truncate></div>
    },
  },
  {
    title: 'Thời gian',
    dataIndex: 'time',
    key: 'time',
    align: 'center',
  },
  {
    title: 'Điểm quy đổi',
    dataIndex: 'point',
    key: 'point',
    align: 'center',
  },
];

interface Props extends BaseModalProps {
  onCancel: () => void;
}

const PurchasedVideo: React.FC<Props> = (props) => {

  const handleClose = () => {
    props.onCancel && props.onCancel()
  }

  return (
    <BaseModal
      className="purchaseVideoModal"
      width={'80%'}
      {...props}
    >
      <div className="purchaseVideoWrapper">
        <div className="modalTitle">Danh sách video</div>
        <div className="modalContext">
          <ConfigProvider renderEmpty={() => <Empty image={imagesAssets.empty} description="Bạn chưa đặt sản phẩm nào" />}>
            <Table rowClassName="bg-[#F9F9F9]" scroll={{ y: 456 }} bordered dataSource={dataSource} columns={columns} pagination={false} />
          </ConfigProvider>
        </div>
        <div className="submit-footer">
          <PrimaryBtn className="closeBtn" onClick={handleClose}>
            Đóng
          </PrimaryBtn>
        </div>
      </div>
    </BaseModal>
  );
}

export default PurchasedVideo;
