import React from 'react'
import { SvgIconProps } from './type'

const Done: React.FC<SvgIconProps> = ({ height, width }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.99976 -6.10352e-05C8.02194 -6.10352e-05 6.08855 0.586429 4.44406 1.68524C2.79957 2.78406 1.51784 4.34584 0.760965 6.1731C0.00408874 8.00036 -0.193945 10.011 0.191908 11.9508C0.57776 13.8906 1.53017 15.6725 2.92869 17.071C4.32722 18.4695 6.10905 19.4219 8.04886 19.8078C9.98866 20.1936 11.9993 19.9956 13.8266 19.2387C15.6539 18.4819 17.2156 17.2001 18.3145 15.5556C19.4133 13.9111 19.9998 11.9777 19.9998 9.99994C19.9998 7.34777 18.9462 4.80423 17.0708 2.92887C15.1955 1.05351 12.6519 -6.10352e-05 9.99976 -6.10352e-05V-6.10352e-05ZM15.6152 7.70539L8.8543 14.5236L5.51885 11.1599C5.45555 11.0954 5.4201 11.0085 5.4201 10.9181C5.4201 10.8277 5.45555 10.7409 5.51885 10.6763L6.2934 9.89448C6.32534 9.86224 6.36336 9.83665 6.40526 9.81918C6.44716 9.80172 6.4921 9.79273 6.53749 9.79273C6.58288 9.79273 6.62782 9.80172 6.66971 9.81918C6.71161 9.83665 6.74963 9.86224 6.78158 9.89448L8.85521 11.9854L14.3534 6.43994C14.3855 6.40778 14.4236 6.38226 14.4656 6.36485C14.5075 6.34745 14.5525 6.33848 14.5979 6.33848C14.6434 6.33848 14.6884 6.34745 14.7303 6.36485C14.7723 6.38226 14.8104 6.40778 14.8425 6.43994L15.6161 7.22176C15.6797 7.28622 15.7153 7.37319 15.7152 7.46376C15.715 7.55433 15.6791 7.64117 15.6152 7.70539Z" fill="#1F91FA" />
    </svg>
  )
}

export default Done