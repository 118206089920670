import { atom, selector } from "recoil";
import { brandTotal } from "../containers/HomePage/constant";
import { VideoType } from "../types";

interface videoListAtomType {
  videos: VideoType[];
  total: number;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  filter: {
    category?: string;
    customerId?: string;
    search?: string;
  };
}

const videosAtom = atom<videoListAtomType>({
  key: "videosAtom",
  default: {
    total: 0,
    videos: [],
    loading: false,
    currentPage: 1,
    pageSize: 4,
    filter: {
      customerId: brandTotal._id
    },
  },
});

export default videosAtom;
