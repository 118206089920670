import React from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { getCategories } from '../../apis';
import HorizontalScrolling from '../../components/Scroll/HorizontalScrolling';
import categoriesAtom, { CategoryType } from '../../recoil/categoriesAtom';
import userInfoAtom from '../../recoil/userInfoAtom';
import videosAtom from '../../recoil/videosAtom';
import { categoryTotal } from './constant';

const Categories: React.FC = () => {
  const setCategoriesStore = useSetRecoilState(categoriesAtom);
  const categoriesState = useRecoilValue(categoriesAtom);
  const userInfo = useRecoilValue(userInfoAtom);
  const [videosStore, setVideosStore] = useRecoilState(videosAtom)
  const { filter } = videosStore

  const handleSelectStore = (item: CategoryType) => {
    setVideosStore(value => ({
      ...value,
      filter: {
        ...value?.filter,
        category: item.slug
      }
    }))
  }

  const renderItem = (item: CategoryType, index: number) => {
    const isActiveCategory = item.slug === filter?.category || (!filter?.category && index === 0)
    const activeStyle = isActiveCategory ? 'bg-[#030303] text-white' : 'bg-[#F2F2F2]'

    const style = `item cursor-pointer rounded-[10px] py-1 px-4 mr-3 w-max ${activeStyle}`

    return <div key={item?._id} className={style} onClick={() => handleSelectStore(item)}>{item?.name}</div>
  }

  const renderCategories = () => {
    if (categoriesState?.loading) {
      return <></>
    }

    const categoriesAll = [categoryTotal, ...categoriesState?.categories]

    return (
      <div className="p-3 bg-white flex border-t border-[#E5E5E5] overflow-x-auto whitespace-nowrap">
        {categoriesAll.map((item, index) => renderItem(item, index))}
      </div>
    )
  }

  React.useEffect(() => {
    const getX = () => {
      setCategoriesStore({ loading: true, categories: [] })
      getCategories({ customer_id: userInfo?.customer_id }).then((result) => {
        if (result) {
          setCategoriesStore({ loading: false, categories: result.categories })
        }
      })
    }

    getX()
  }, [userInfo?.customer_id])

  return renderCategories()
}

export default Categories