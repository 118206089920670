import React from 'react'
import { SvgIconProps } from './type'

const Play: React.FC<SvgIconProps> = ({ width = 24, height = 24, color = 'white' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.19263 21C4.06238 21 3.93221 21 3.80195 21C3.18501 20.8116 2.99859 20.4107 3.00001 19.8459C3.01293 14.6036 3.00759 9.36108 3.00792 4.11873C3.00792 4.02506 3.00584 3.93101 3.01376 3.83779C3.04493 3.47008 3.25893 3.21854 3.62795 3.06832C3.68561 3.04492 3.74395 3.02272 3.80195 3C3.93221 3 4.06238 3 4.19263 3C4.33889 3.06097 4.49031 3.11355 4.6304 3.18434C8.84712 5.31665 13.0627 7.45061 17.2783 9.5845C18.3265 10.1151 19.3738 10.6473 20.4229 11.1765C20.7865 11.3599 21.0035 11.624 21 12.0088C20.9962 12.4194 20.7341 12.6681 20.3556 12.8595C15.7813 15.1713 11.2105 17.4886 6.63622 19.8007C5.82727 20.2095 5.00741 20.6006 4.19263 21Z" fill={color} />
    </svg>

  )
}

export default Play