import * as React from "react";
import { Form, Input, Row, Col, InputNumber, Button, Select } from "antd";
import PrimaryBtn from "../../Button/PrimaryBtn";
import BaseModal, { BaseModalProps } from "../BaseModal";
import { useRecoilState } from "recoil";
import userInfoAtom from "../../../recoil/userInfoAtom";
import { postSignIn, postSignUp } from "../../../apis";
import appModalAtom from "../../../recoil/appModalAtom";
import { ModalType } from "../../../types/enum";
import { UserType } from "../../../types";
import { useEffect, useState } from "react";
import imagesAssets from "../../../assets/imgs";
import MainButton from "../../Button/MainButton";

const { Option } = Select;

enum IScreenType {
  DEFAULT,
  CHECK_EMAIL,
}

interface Props extends BaseModalProps {
  data?: any;
}

function UserModal(props: Props) {
  const [appModal, setAppModal] = useRecoilState(appModalAtom);
  const { mode } = appModal;
  // const [mode, setMode] = React.useState<ModalType>(appModal.modal);
  const [form] = Form.useForm();
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);
  const [loading, setLoading] = useState(false);
  const [screenType, setScreenType] = useState<IScreenType>(
    IScreenType.DEFAULT
  );

  const handleClose = (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
    userInfoResult?: UserType
  ) => {
    if (props?.data?.onCancel) {
      props?.data?.onCancel(userInfoResult);
    } else {
      props?.onCancel && props?.onCancel(e);
    }
  };

  const handleSubmit = async () => {
    const fieldsValue = await form.validateFields();
    try {
      setLoading(true);
      let userInfoResult: UserType = {};
      if (mode === ModalType.signIn) {
        const { password } = fieldsValue;
        const res = await postSignIn({ password });
        userInfoResult = { ...res?.user, api_key: res.api_key };
        setUserInfo(userInfoResult);

        handleClose({} as any, userInfoResult);
      } else {
        const {
          firstName,
          lastName,
          email,
          phone,
          userType,
          company,
          companyAddress,
          refCode,
        } = fieldsValue;
        const res = await postSignUp({
          firstName,
          lastName,
          email,
          phone,
          userType,
          company,
          companyAddress,
          refCode,
        });

        setScreenType(IScreenType.CHECK_EMAIL);
      }
      form.resetFields();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!props.open) {
      setScreenType(IScreenType.DEFAULT);
    }
  }, [props.open]);

  const renderSignInForm = () => (
    <>
      <Form.Item
        label="Mã khách hàng"
        name="password"
        rules={[{ required: true, message: "Thông tin bắt buộc" }]}
      >
        <Input placeholder="Mã khách hàng" />
      </Form.Item>
      <div>
        Tôi chưa có mã khách hàng.
        <Button
          type="link"
          onClick={() =>
            setAppModal({ mode: ModalType.signUp, data: props.data })
          }
        >
          Đăng ký
        </Button>
      </div>
    </>
  );

  const renderSignUpForm = () => (
    <>
      <Row gutter={{ lg: 15 }}>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Họ"
            name="firstName"
            rules={[{ required: true, message: "Thông tin bắt buộc" }]}
          >
            <Input placeholder="Họ" />
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item
            label="Tên"
            name="lastName"
            rules={[{ required: true, message: "Thông tin bắt buộc" }]}
          >
            <Input placeholder="Tên" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Địa chỉ email"
        name="email"
        rules={[
          { required: true, message: "Thông tin bắt buộc" },
          { type: "email", message: "Email chưa chính xác" },
        ]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        label="Điện thoại"
        name="phone"
        rules={[{ required: true, message: "Thông tin bắt buộc" }]}
      >
        <InputNumber placeholder="Điện thoại" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Mã giới thiệu"
        name="refCode"
        rules={[{ required: false, message: "Mã giới thiệu" }]}
      >
        <Input
          placeholder="Nhập mã giới thiệu để được thưởng điểm hoặc bỏ qua"
          style={{ width: "100%" }}
        />
      </Form.Item>
      <div>
        Tôi đã có mã khách hàng
        <Button
          type="link"
          onClick={() =>
            setAppModal({ mode: ModalType.signIn, data: props.data })
          }
        >
          Đăng nhập
        </Button>
      </div>
    </>
  );

  if (screenType === IScreenType.CHECK_EMAIL) {
    return (
      <BaseModal
        className="purchaseVideoModal"
        {...props}
        onCancel={handleClose}
      >
        <div className="purchaseVideoWrapper md:justify-center">
          <div>
            <div className="title md:text-[#1F91FA] md:mb-4 md:font-semibold">
              Đăng ký thành công
            </div>

            <div className="mt-5 mb-5 text-center">
              <p>
                Vui lòng kiểm tra email để lấy mã đăng nhập
                <br />
                (Trong phần hộp thư đến hoặc trong Spam)
              </p>
            </div>
            <div className="gap-5 flex justify-center">
              <MainButton className="closeBtn" onClick={handleClose}>
                Đồng ý
              </MainButton>
            </div>
          </div>
        </div>
      </BaseModal>
    );
  }

  return (
    <BaseModal className="userModal" {...props} onCancel={handleClose}>
      <Form className="userWrapper" layout="vertical" form={form}>
        <div className="title md:text-[#1F91FA] md:mb-4 md:font-semibold">{`${
          mode === ModalType.signIn ? "Đăng nhập" : "Đăng ký"
        }`}</div>
        {mode === ModalType.signIn ? renderSignInForm() : renderSignUpForm()}

        <div className="submit-footer">
          <Form.Item shouldUpdate>
            {() => (
              <PrimaryBtn
                className="md:w-full md:!rounded-[100px] md:mt-4"
                disabled={
                  !form.isFieldsTouched(true) ||
                  !!form.getFieldsError().filter(({ errors }) => errors.length)
                    .length
                }
                onClick={handleSubmit}
                loading={loading}
              >
                {mode === ModalType.signIn ? "Đăng nhập" : "Đăng ký"}
              </PrimaryBtn>
            )}
          </Form.Item>
        </div>
      </Form>
    </BaseModal>
  );
}

export default UserModal;
