import { atom, selector } from "recoil";

export interface CategoryType {
  _id: string;
  created_at: string;
  name: string;
  slug: string;
  updated_at: string;
}

export interface CategoriesType {
  categories: CategoryType[]
  loading: boolean
}

const categoriesAtom = atom<CategoriesType>({
  key: "categoriesAtom",
  default: {
    categories: [],
    loading: false
  },
});

const categoriesState = selector({
  key: 'categoriesState',
  get: ({get}) => {
    return get(categoriesAtom).categories;
  },
});

export default categoriesAtom;
