import { atom } from "recoil";

export interface IAppConfigAtom {
  loading: boolean
}

const appConfigAtom = atom<IAppConfigAtom>({
  key: "appConfigAtom",
  default: {
    loading: false
  },
});

export default appConfigAtom;
