import { useRecoilState } from "recoil";
import { apis } from "../../apis";
import userInfoAtom from "../../recoil/userInfoAtom";

const useInfoProfile = () => {
  const [userInfo, setUserInfo] = useRecoilState(userInfoAtom);

  const handleRefreshProfile = async () => {
    if (!userInfo?.email) return

    try {
      const res = await apis.getMe()

      setUserInfo((values) => ({ ...values, ...res?.user }))
    } catch (error) {
      console.log('error', error)
    }
  }

  return { userInfo, handleRefreshProfile };
};

export default useInfoProfile;
