import React, { useState } from 'react'
import "./styles.scss";
import HorizontalScrolling from "../../Scroll/HorizontalScrolling";
import { formatCurrency } from "../../../utils/format";
import { Product } from '../../../types';

import imagesAssets from "../../../assets/imgs";


interface IProductItemProps {
  item: Product,
  itemId: string;
  onSelect: (item: Product) => void;
  selected?: boolean;
}

const ProductItem: React.FC<IProductItemProps> = ({ item, selected, onSelect }) => {
  const handleSelectItem = () => {
    onSelect && onSelect(item)
  }


  return (
    <div onClick={handleSelectItem} className={`productItem h-full ${selected ? 'active' : ''}`}>
      <div className="productImage">
        <img className='min-h-[100px]' src={item?.product_photo ?? imagesAssets.product} alt={item.name} />
        <div className="priceWrapper">
          <div className="price">
            {item.sale_price ? <><span className='line-through'>{formatCurrency(item.price)}</span>
              <br /></> : <></>}

            {formatCurrency(item.sale_price)}
          </div>
        </div>
      </div>
      <div className="productName">{item.name}</div>
    </div>
  )
}

interface IProductProps {
  products: Product[]
  productsSelected: Record<string, number | undefined>;
  onselect: (item: Product) => void
}

const Products: React.FC<IProductProps> = ({ products, onselect }) => {
  const [productsSelected, setProductsSelected] = useState<Record<string, number | undefined>>({})

  const handleSelectItem = (item: Product) => {
    onselect && onselect(item)
    let productsSelectedClone = { ...productsSelected }

    if (productsSelected[item?._id]) {
      productsSelectedClone[item?._id] = undefined
    } else {
      productsSelectedClone = { ...productsSelectedClone, [item?._id]: 1 }
    }

    setProductsSelected(productsSelectedClone)
  }

  const renderItem = (item: Product) => <ProductItem onSelect={handleSelectItem} selected={!!productsSelected[item?._id]} item={item} itemId={item._id} />

  if (!products || products.length === 0) {
    return <>Không có sản phẩm để chọn</>
  }

  return <>
    <HorizontalScrolling
      className="products"
      data={products}
      renderItem={renderItem} />
  </>
}

export default Products;
