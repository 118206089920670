import * as React from "react";
import PrimaryBtn from "../Button/PrimaryBtn";
import SecondaryBtn from "../Button/SecondaryBtn";
import BaseModal, { BaseModalProps } from "./BaseModal";
import "./styles.scss";

interface Props extends BaseModalProps {
  onClose: () => void;
}

function ThanksUserCodeModal(props: Props) {
  return (
    <BaseModal
      footer={[
        <SecondaryBtn onClick={props.onClose}>Đóng</SecondaryBtn>,
        <PrimaryBtn onClick={props.onClose}>Xem thêm video</PrimaryBtn>,
      ]}
      {...props}
    >
      Chúng tôi sẽ gửi thông báo điểm thưởng và giá trị quy đổi vào email của bạn.
    </BaseModal>
  );
}

export default ThanksUserCodeModal;
