import React from 'react'
import { SvgIconProps } from './type'

const Search: React.FC<SvgIconProps> = ({ height = 20, width = 20 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_298_462)">
        <path fillRule="evenodd" clipRule="evenodd" d="M0.000366211 9.37501C0.000366211 8.9974 0.000366211 8.6198 0.000366211 8.2422C0.0116933 8.19196 0.0294652 8.14225 0.0334688 8.09143C0.139319 6.73994 0.532302 5.47584 1.23786 4.32076C2.61625 2.06442 4.6047 0.66542 7.20491 0.153551C7.54755 0.0860764 7.89645 0.050435 8.24251 0C8.62012 0 8.99777 0 9.37528 0C9.43172 0.0117177 9.48781 0.0252907 9.54455 0.034909C10.0438 0.119521 10.5531 0.16561 11.0408 0.293578C15.1584 1.37424 17.9236 5.25867 17.5851 9.48471C17.4468 11.2104 16.8527 12.7586 15.8118 14.1383C15.7382 14.236 15.6625 14.332 15.569 14.4529C15.632 14.5038 15.6924 14.5441 15.7429 14.5946C16.9636 15.8132 18.1834 17.0326 19.4027 18.2526C19.5451 18.3952 19.6958 18.5324 19.8207 18.6894C20.152 19.1058 20.0061 19.694 19.5253 19.9224C19.4745 19.9465 19.4253 19.974 19.3753 19.9999C19.2581 19.9999 19.1409 19.9999 19.0237 19.9999C18.8253 19.8592 18.6028 19.7429 18.432 19.5742C17.215 18.3712 16.008 17.1579 14.799 15.9467C14.6797 15.8273 14.5681 15.7 14.4487 15.5719C14.3612 15.6395 14.2946 15.6908 14.2283 15.7424C12.7455 16.8946 11.0641 17.5234 9.19048 17.6046C7.27414 17.6876 5.5012 17.2006 3.91408 16.12C1.8774 14.7332 0.615888 12.8207 0.150841 10.3945C0.0861985 10.0576 0.0498736 9.71502 0.000366211 9.37501ZM8.81229 15.9841C12.7726 15.9768 16.0015 12.7415 15.9843 8.79805C15.9669 4.83595 12.77 1.64077 8.81307 1.63057C4.86556 1.62046 1.63206 4.85143 1.63079 8.80704C1.62967 12.7609 4.86224 15.9915 8.81229 15.9841Z" fill="#030303" />
      </g>
      <defs>
        <clipPath id="clip0_298_462">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Search