import { message } from "antd";
import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useRecoilState } from "recoil";
import { apis, EPurchase } from "../../../apis";
import imagesAssets from "../../../assets/imgs";
import appModalAtom from "../../../recoil/appModalAtom";
import { VideoType } from "../../../types";
import { ModalType } from "../../../types/enum";
import { formatPoint } from "../../../utils";
import { formatShowCurrency } from "../../../utils/format";
import MainButton from "../../Button/MainButton";
import PrimaryBtn from "../../Button/PrimaryBtn";
import useInfoProfile from "../../useHook/useInfoProfile";
import BaseModal from "../BaseModal";
import "./styles.scss";

interface Props {
  open: boolean;
  onCancel: () => void;
  data: VideoType
  extraPurChase?: () => void
  extraCloseViewMore?: () => void
}

enum ITypeScreen {
  purchase,
  notEnoughBalance
}

const PurchaseVideoModal: React.FC<Props> = ({ open, onCancel, data, extraPurChase, extraCloseViewMore }) => {
  const history = useHistory();
  const { userInfo, handleRefreshProfile } = useInfoProfile()
  const [, setAppModal] = useRecoilState(appModalAtom);

  const [isLoading, setIsLoading] = React.useState(false)
  const [typeScreen, setTypeScreen] = React.useState<ITypeScreen>(ITypeScreen.purchase)

  const handleClose = () => {
    onCancel && onCancel()
  }

  const handleCloseViewMore = () => {
    if (extraCloseViewMore) {
      extraCloseViewMore()
    } else {
      handleClose()
    }
  }

  const handleSubmit = async () => {
    // console
    setIsLoading(true)

    try {
      const response = await apis.purchaseVideo(data.id)
      console.log('response', response)
      if ([EPurchase.PURCHASED, EPurchase.SUCCESS].includes(response?.message)) {
        onCancel()
        history.push(`/videos/${data?.id}`)
        handleRefreshProfile()
        extraPurChase && extraPurChase()
      } else {
        setTypeScreen(ITypeScreen.notEnoughBalance)
      }
    } catch (error) {
      setTypeScreen(ITypeScreen.notEnoughBalance)
    }
    setIsLoading(false)
  }

  const handleLogin = () => {
    setAppModal({ mode: ModalType.signIn })
  }

  const renderButtonOrderVideo = () => {
    if (userInfo?.email) {
      return (
        <MainButton loading={isLoading} className="closeBtn" onClick={handleSubmit}>
          Đồng ý
        </MainButton>
      )
    }

    return <MainButton loading={isLoading} className="closeBtn" onClick={handleLogin}>
      Đăng nhập
    </MainButton>
  }

  const renderPurchaseScreen = () => {
    return <div>
      <div className="mt-5 mb-5 text-center">
        <p>Đây là video cần đổi điểm để xem.</p>
        <p>Bạn cần <span className="text-[#1F91FA] font-semibold">{formatShowCurrency(data?.price, true)}</span> để xem video này. Bạn có muốn đổi?</p>
      </div>
      <div className="gap-5 flex justify-center">
        <PrimaryBtn
          className="cancelBtn md:!rounded-[100px]"
          onClick={handleClose}
        >
          Hủy
        </PrimaryBtn>
        {renderButtonOrderVideo()}
      </div>
    </div>
  }

  const renderErrorScreen = () => {
    return <div>
      <div className="mt-5 mb-5 text-center">
        <p>Không đủ điểm.</p>
        <p>Xem thêm video miễn phí để tích lũy thêm điểm nhé!</p>
      </div>
      <div className="gap-5 flex justify-center">
        <MainButton loading={isLoading} className="closeBtn" onClick={handleCloseViewMore}>
          Đồng ý
        </MainButton>
      </div>
    </div>
  }

  const renderContent = () => {
    if (_.isNil(data) || (!_.isNil(userInfo?.points) && data && userInfo.points < data?.price)) {
      return renderErrorScreen()
    }

    return typeScreen === ITypeScreen.purchase ? renderPurchaseScreen() : renderErrorScreen()
  }

  React.useEffect(() => {
    return () => {
      setTypeScreen(ITypeScreen.purchase)
      setIsLoading(false)
    }
  }, [open])

  return (
    <BaseModal
      className="purchaseVideoModal"
      open={open}
      onCancel={onCancel}
    >
      <div className="purchaseVideoWrapper md:justify-center">
        <div className="flex justify-center">
          <img className="self-center" src={imagesAssets.purchase} alt="thanks" />
        </div>
        {renderContent()}
      </div>
    </BaseModal>
  );
}

export default PurchaseVideoModal;
